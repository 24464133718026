.wrapper-cancel>.ant-modal-content>.ant-modal-header {
    border-bottom: 1px solid #D0D2D8;
    height: 60px;
    /* margin: 8px 12px 0 0; */
    display: flex;
    align-items: center;
  }
  .wrapper-cancel>.ant-row>.ant-col>.ant-modal-content>.ant-modal-header>.ant-modal-title {
    color: #6C747B;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
  .wrapper-cancel .ant-modal-close {
    margin: 6px 12px 0 0;
  }
  .cancel-container {
    margin-top: 32px;
  }
  .cancel-container>.ant-row>.ant-col>.ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  .cancel-container>.ant-row>.ant-col>.ant-radio-group>.ant-radio-wrapper:not(.ant-radio-wrapper:last-child) {
    padding-bottom: 20px;
  }
  .cancel-container>.ant-row>.ant-col>.ant-radio-group>.ant-radio-wrapper:last-child {
    padding-bottom: 40px;
  }
  .cancel-container .footer_cancel {
    background-color: #f0f5f8;
    color: #6C747B;
    width: 100%;
    height: 60px;
    cursor: pointer;
    border: none;
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }
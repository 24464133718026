.content {
  max-width: 1840px;
  /* box-shadow: 0px 4px 4px 0px rgba(46, 49, 56, 0.2), 0px 2px 2px 0px rgba(46, 49, 56, 0.2); */
  margin-top: -10px;
}
.bookingTab .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
  display: none;
}

/* overwrite antd-css */
.content>.ant-row>.bookingTab .ant-tabs-content-holder {
  padding: 40px 240px 100px;
  box-shadow: 0px 4px 4px 0px rgba(46, 49, 56, 0.2), 0px 2px 2px 0px rgba(46, 49, 56, 0.2);
}
.content>.ant-row>.bookingTab>.ant-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab {
  border: none;
}
.content>.ant-row>.bookingTab>.ant-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab:nth-child(1) {
  border-right: 1px solid rgba(46, 49, 56, 0.2);
}
.bookingTab .ant-tabs-tab,
.bookingTab .ant-tabs-tab-active {
  margin: 0 !important;
  color: #707070;
  width: 300px !important;
  justify-content: center;
}
.content>.ant-row>.bookingTab .ant-tabs-card.ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 -5px 0;
}
.content>.ant-row>.bookingTab>.ant-tabs >.ant-tabs-nav>.ant-tabs-nav-wrap {
  overflow: visible !important;
}
.bookingTab>.ant-tabs-card.ant-tabs-top > .ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab {
  border-radius: 0;
  border-bottom: none !important;
}
.content>.ant-row>.bookingTab .ant-tabs-nav-list {
  box-shadow: 0px -1px 0px 0px rgba(46, 49, 56, 0.2), -1px 0px 0px 0px rgba(46, 49, 56, 0.2);
  border-right: 1px solid rgba(46, 49, 56, 0.2);
}
.bookingTab .ant-tabs-nav-list .ant-tabs-tab-btn span {
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.bookingTab  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  min-width: 1300px;
}

.bookingTab .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  box-shadow: none;
}

/* mobile */
@media (max-width: 768px) {
  .content { 
    margin-top: 0px;
  }
  /* overwrite antd-css */
  .content .ant-tabs-content-holder {
    padding: 80px 24px 36px;
  }
  .bookingTab .ant-tabs-tab,
  .bookingTab .ant-tabs-tab-active {
    min-width: unset;
    width: 100%;
  }
  .bookingTab .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    display: block;
  }
  .content>.ant-row>.bookingTab .ant-tabs-content-holder {
    padding: 30px !important;
  }
}

/* tablet */
@media (min-width: 768px) and (max-width: 1200px)  { 
  /* overwrite antd-css */
  .content .ant-tabs-content-holder {
    padding: 80px 36px 36px;
  }
}

/* tablet & PC */
@media (min-width: 1200px) and (max-width: 1600px)  { 
  /* overwrite antd-css */
  .content .ant-tabs-content-holder {
    padding: 80px 100px 36px;
  }
}
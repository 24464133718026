  .ant-picker-calendar .ant-picker-calendar-header {
    display: none;
  }

  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 0 !important
  }

  .disabled-day {
    opacity: 0.4;
  }

  .button-disabled {
    disabled: true;
  }

  .ant-picker-content thead th {
    visibility: hidden;
    text-align: center;
    left: 15px;
  }

  .ant-picker-content thead th:nth-child(2)::before {
    visibility: visible;
    content: "\6708";
    /* 月 */
    color: #565C67;
    text-align: center;
    font-family: Meiryo;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .ant-picker-content thead th:nth-child(3)::before {
    visibility: visible;
    content: "\706B";
    /* 火 */
    color: #565C67;
    text-align: center;
    font-family: Meiryo;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .ant-picker-content thead th:nth-child(4)::before {
    visibility: visible;
    content: "\6C34";
    /* 水 */
    color: #565C67;
    text-align: center;
    font-family: Meiryo;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .ant-picker-content thead th:nth-child(5)::before {
    visibility: visible;
    content: "\6728";
    /* 木 */
    color: #565C67;
    text-align: center;
    font-family: Meiryo;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .ant-picker-content thead th:nth-child(6)::before {
    visibility: visible;
    content: "\91D1";
    /* 金 */
    color: #565C67;
    text-align: center;
    font-family: Meiryo;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .ant-picker-content thead th:nth-child(7)::before {
    visibility: visible;
    content: "\571F";
    /* 土 */
    color: #308AE6;
    text-align: center;
    font-family: Meiryo;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .ant-picker-content thead th:nth-child(1)::before {
    visibility: visible;
    content: "\65E5";
    /* 日 */
    color: #FF1493;
    text-align: center;
    font-family: Meiryo;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
  }

  .ant-picker-cell,
  .ant-picker-cell-in-view {
    position: relative;
    border-color: #1890ff;
    border: 1px solid #ECEDEF;
  }

  .full-calendar {
    border-radius: 0px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
  }

  .header-calendar {
    border: 1px solid #ECEDEF;
    display: flex;
    height: 60px;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 0px;
    border-bottom: 1px solid #ECEDEF;
  }

  .full-calendar table thead tr th {
    border: 1px solid #ECEDEF;
    height: 50px !important;
  }

  .ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date {
    padding: 0 0 0 0;
    border-top: 0;
    margin: 0;
  }

  .ant-picker-calendar-date-value {
    display: none;
  }

  .ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content {
    text-align: center !important;
    color: #565C67;
    text-align: center;
    font-family: Meiryo;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
  }

  .ant-picker-cell {
    vertical-align: middle !important;
  }

  .ant-picker-calendar-date-content {
    display: flex;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
  }

  .ant-badge.ant-badge-status .ant-badge-status-dot {
    right: -18px;
    width: 12px;
    height: 12px;
  }

  .ant-picker-calendar.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
    white-space: nowrap !important;
  }
  @media (max-width: 1200px) {
    .ant-badge.ant-badge-status .ant-badge-status-dot {}

    .content-calendar {
      display: contents;
    }

    .icon-full {
      margin-left: auto !important;
    }

    .icon-empty {
      margin-left: auto !important;
    }

    .day-number {
      margin-bottom: 38px !important;
    }
    .container-calendar {
      align-items: initial;
    }

  }

  .label-year {
    color: #565C67;
    text-align: center;
    font-family: Meiryo;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    /* 160% */
  }

  .label-month {
    color: #565C67;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    /* 114.286% */
  }

  .button-prev {
    background-color: #F0F8FF;
    color: #308AE6;
    font-size: 14px;
    line-height: 14px;
  }

  .button-next {
    background-color: #F0F8FF;
    color: #308AE6;
    font-size: 14px;
    line-height: 14px;
  }

  .ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-calendar-date {
    background: none !important;
  }

  .div-icon {
    margin-top: -18px;
  }

  .icon-full {
    margin-left: 65px;
  }

  .icon-empty {
    margin-left: 65px;
  }

  .ant-picker-cell-today .ant-picker-calendar-date-content .day-number {
    margin-top: -5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #308AE6;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .ant-picker-cell-today .ant-picker-calendar-date-content .div-icon {
    margin-top: -25px;
  }

.modalEditReversion .title {
    text-align: center;
}
.modalEditReversion .orderList {
    border-radius: var(--radius-slightly-rounded, 6px);
    background: var(--background-footer, #F8F8F8);
    padding: 24px;

    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100% - 32px);
}
.modalEditReversion .orderList .itemTitle {
    font-size: 17px;
    line-height: 32px;
    margin-bottom: 4px;
}
.modalEditReversion .notice {
    display: flex;
}
.modalEditReversion .notice img {
    margin-right: 6px;
}
.modalEditReversion .titleReservation {
    font-size: 15px;
    margin-bottom: 8px;
    height: 24px;
}
.modalEditReversion .policy {
    display: flex;
    gap: 8px;
    font-size: 15px;
}

/* overwrite antd-css */
.modalEditReversion .ant-modal-body {
    padding-right: 15px;
}
.ant-modal-wrap .modalEditReversion {
    width: 640px !important;
}
.modalEditReversion>.ant-modal-content>.ant-modal-header {
    border-bottom: 1px solid #D0D2D8;
    height: 60px;
    display: flex;
    align-items: center;
}
.modalEditReversion>.ant-modal-content>.ant-modal-header>.ant-modal-title {
    color: #6C747B;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}
.modalEditReversion>.ant-modal-content>.ant-modal-close {
    margin: 16px 8px 0 0;
}
.modalEditReversion>.ant-modal-content>.ant-modal-body>.ant-row>.bodyModal {
    margin-top: 32px;
}

.buttonPt10-left-disable {
    border-radius: 6px;
    border: none;
    background: #FFC7E3 !important;
    width: -webkit-fill-available;
    height: 60px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    cursor: pointer;
}

.buttonPt10-left-disable > span{
    color: #FFFFFF;
}

/* mobile-css */
@media (max-width: 768px) {
    /* overwrite antd-css */
    .ant-modal-wrap .modalEditReversion {
        width: 400px !important;
    }
    .modalEditReversion .notice {
        text-align: center;
    }
}
  
.schedule {
  background: #ffffff;
  margin-right: -40px;
  margin-left: -40px;
  width: calc(100% + 80px);
}
.schedule>.ant-row>.bookingTab>.ant-tabs-card.ant-tabs-top > .ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-disabled {
  background-color: #ffffff;
  border: none;
  display: contents;
}
.schedule>.ant-row>.bookingTab>.ant-tabs-card.ant-tabs-top > .ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-disabled>.ant-tabs-tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
}

.button-in-tabs {
  left: 44vw;
  min-width: 120px;
  background-color: #ff1493;
  color: #ffffff;
  width: 100%;
  height: 36px;
  cursor: pointer;}

/* mobile */
@media (max-width: 768px) {
  .bookingTab {
    min-height: 600px;
  }

  .button-in-tabs{
    left: 20vw;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .button-in-tabs {
    left: 15vw !important;
  }
}

@media (min-width: 1366px) and (max-width: 1600px) {
  .button-in-tabs {
    left: 25vw !important;
  }
}
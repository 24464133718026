
.confirmEditCard .ant-modal-header {
    border-bottom: none !important;
}

.confirmEditCard .ant-modal-content {
    width: 800px;
    left: calc(50% - 400px);
    border-radius: 0 !important;
}

.confirmEditCard .confirmBtn {
    background: #FF1493;
    width: 300px;
    color: #FFFFFF;
    height: 60px;
    font-weight: 700;
    font-size: 16px;
}

.confirmEditCard .confirmBtn:hover {
    background: #FF1493 !important;
    color: #FFFFFF !important;
}

.confirmEditCard .ant-modal-title {
    font-weight: 700;
    font-size: 17px;
    color: #565C67;
    border-bottom: solid 1px #D0D2D8;
    padding: 17px 0;
    margin-top: 7px;
}

.confirmEditCard .cardList img {
    width: 70px;
    height: 48px;
    margin-right: 8px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.confirmEditCard input {
    height: 58px;
}

.confirmEditCard table {
    font-size: 15px;
}

.confirmEditCard .formTable tr:nth-child(3) td {
    height: 50px;
    vertical-align: bottom;
}

.confirmEditCard .info1 {
    font-size: 14px;
    font-weight: 700;
    color: #2E3138;
}

.confirmEditCard table {
    width: 100%;
    margin-bottom: 64px;
}

.confirmEditCard table td {
    height: 56px !important;
    border-bottom: 1px solid #F2F3F5;
    padding-bottom: 0;
    vertical-align: bottom;
}

.confirmEditCard table tr td:nth-child(2) {
    text-align: right;
    color: #565C67;
    font-weight: 700;
}


.banner img{
    width: 100%;
    height: 100%;
    max-height: 400px;
    object-fit: cover;
}
.banner {
    min-height: 200px;
}

@media (max-width: 768px) { 
    .banner img{ 
        position: absolute;
        max-height: unset;
    }
    .banner {
        min-height: 0;
    }
}
.booking-online .title {
  margin-bottom: 20px;
}
.booking-online .title {
  color: #565c67;
  font-size: 20px;
  line-height: normal;
}

@media (max-width: 768px) {
  .booking-online .title {
    text-align: center;
  }
}
.new-address .detailPharmacy {
    border-radius: var(--radius-slightly-rounded, 6px);
    background: var(--background-footer, #F8F8F8);
    padding: 24px;
}

.new-address {
    display: grid;
    align-items: center;
    padding: 24px;
    border-radius: 6px;
    background: #F2F3F5 !important;
}

.new-address .prescription-info-button {
    justify-content: center;
    align-items: center;
}

.new-address .prescription-info-button .buttonCancel {
    height: 60px;
    background: #8B969E;
    color: #ffffff;
    width: 100%;
}

.new-address .prescription-info-button .buttonCancel:hover {
    color: #ffffff;
}

.new-address .prescription-info-button .buttonAccept {
    height: 60px;
    background: #FF1493;
    color: #ffffff;
    cursor: pointer;
    margin-top: unset;
    width: 100%;
}

.new-address .ant-row .ant-col .button-disabled {
    border-radius: 6px;
    background: #FFC7E3;
}

.new-address .new-prescription-btn-cancel {
    flex: 1;
    text-align: end;
}

.new-address .new-prescription-btn-cancel .cancel-button-step {
    border-radius: 6px;
    background: #8B969E;
    color: #FFFFFF;
    font-size: 16px;
    height: 30px;
}

.new-address>.ant-row-title-and-btn {
    padding-top: 36px;
    align-items: center;
    padding-bottom: 10px;
}

.new-prescription-info-label input {
    height: 50px;
}

.new-address .new-prescription-info-row {
    display: flex !important;
    grid-gap: 0 !important;
}

.QR-line-content {
    display: flex;
    background: #FFFFFF;
  }

.QR-line-content>.qrcode {
margin: 10px;
padding: 2px;
width: 104px;
height: 104px;
background-color: #565C67;
display: flex;
}

.QR-line-content>div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.QR-line-content>div>span {
color: #565C67 !important;
font-size: 13px !important;
font-weight: 400 !important;
line-height: 16px !important;
}

.detailPharmacy .line-content-mobile{
    display: none;
}


.search_pharmacy>.prescription-info-label>span {
    color: #2E3138;
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
}
.new-address .search_pharmacy_regis {
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    width: 100%;
    padding: 30px;
    position: absolute;
    top: 85%;
    z-index: 10;
}
.new-address .search_pharmacy_regis .modal-keyword-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 170px;
    overflow-x: auto;
}
.new-address .search_pharmacy_regis .modal-keyword-item, .modal-keyword-item {
    background: #edf4fc;
    border: 1px solid #1e6097;
    border-radius: 2em;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    margin: 0.75em 0.75em 0 0;
    line-height: 1;
    padding: 0.4em 1.25em;
    white-space: nowrap;
    transition: 0.5s all;
}
.new-address .relativeRow {
    position: relative;
}
.new-address .search_pharmacy_regis {
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    min-width: calc(200% - 24px);
    padding: 30px;
    position: absolute;
    left: 0;
    z-index: 10;
    top: 90px;
}
.new-address .result_pref {
    left: 12px;
}
.new-address .result_city {
    left: calc(-200% + 5px);
}

.new-address>.detailPharmacy>.ant-col>div>.itemTitle>span {
    color: #565C67;
    font-size: 17px;
    font-weight: 700;
    line-height: normal;
}

@media (max-width: 768px) {
    .new-address .search_pharmacy_regis {
        min-width: 100px;
    }
    .new-address .result_city {
        left: calc(-120% + 24px);
    }

    .detailPharmacy .QR-line-content{
        display: none;
    }

    .detailPharmacy .line-content-mobile{
        display: grid;
        background-color: #FFFFFF;
        border-radius: 36px;
        padding: 16px;
        box-shadow: 0px 0px 4px 0px rgba(46, 49, 56, 0.10), 0px 2px 2px 0px rgba(46, 49, 56, 0.10);
        justify-content: center;
        align-items: center;
    }
    .detailPharmacy .line-content-mobile .line-content-mobile-text {
        padding: 10px;
    }

    .new-address .ant-row .ant-col .line-button {
        border-radius: 30px;
        background: #06AD53;
    }

    .new-address .ant-row .ant-col .line-button span{
        padding-left: 10px;
        color: #FFFFFF;
    }

    .new-prescription-info-row .new-prescription-info-label {
        padding: 0 0 15px 0;
    }
}
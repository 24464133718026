.timeline-booking {
    margin: 20px 0 20px 0;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 0px;
    border: 1px solid #F2F3F5;
    background: #FFF;

    /* box-shadow/strong/low */
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
}

.name-booking {
    display: flex;
    height: 60px;
    padding: 6px 0 0 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 0px;
    border-left: 10px solid #FF1493;
}

.booking-time1 {
    display: flex;
    align-items: center;
    align-self: stretch;
}

.booking-time2 {
    display: flex;
    width: 160px;
    height: 60px;
    padding: 0px 12px;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    gap: 0px;
    border-radius: 0px;
    border: 1px solid #ECEDEF;
}

.booking-time3 {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
}

.border-booking-time {
    display: flex;
    height: 60px;
    padding: 0px 20px;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    border-radius: 0px;
    border: 1px solid #ECEDEF;
}

.name-context {
    padding-left: 30px;
    margin: 0;
    color: #6C747B;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: -0.48px;
}

.day-of-week-context {
    padding-left: 30px;
    margin: 0;
    color: #6C747B;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.32px;
}

.ant-radio-wrapper {
    margin: 0;
}

.infomation .ant-select-selection-item,
.ant-select-item-option-content {
    color: initial !important;
    font-size: 14px;
    line-height: 16px;
}

.ant-radio-group {
    min-width: 100% !important;
}

.dropdown-selection {
    width: 200px;
}

.name-mobile {
    display: none;
}

@media (max-width: 768px) {

    .booking-time1-mobile,
    .booking-time2-mobile {
        width: max-content;
    }

    .booking-time3 {
        overflow: hidden;
    }
    
    .booking-time2-mobile {
        margin-left: 0px;
        padding: 3px 20px;
        margin-left: 10px;
        justify-content: center;
        align-items: center;
        gap: 0px;
        border-radius: 0px;
        border-left: 1px solid #ECEDEF;
    }
    .name-pc {
        display: none;
    }
    .icon-doc {
        display: none;
    }

}

@media (max-width: 800px) {

    .timeline-booking {
        border: none;
        box-shadow: none;
        padding: 0;
        width: inherit;
    }

    .border-booking-time {
        display: inline-flex;
        width: 100%;
        height: 100%;
    }

    .name-mobile {
        display: table-row-group;
    }

    .chooseDoctorModal .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .chooseDoctorModal .ant-modal-title {
        padding: 10px 0 10px 0;
        border-bottom: 1px solid #ECEDEF;
    }

    .chooseDoctorModal .ant-modal-content>.ant-modal-footer>.ant-btn-default,
    .chooseDoctorModal .ant-modal-content>.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
    .chooseDoctorModal .ant-modal-content>.ant-modal-footer>.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
        order: 2;
        width: 45%;
        height: 50px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        border-radius: 6px;
        background: #F0F5F8;
        color: black;
    }

    .chooseDoctorModal .ant-modal-content>.ant-modal-footer>.ant-btn-primary,
    .chooseDoctorModal .ant-modal-content>.ant-modal-footer>.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active,
    .chooseDoctorModal .ant-modal-content>.ant-modal-footer>.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
        order: 1;
        height: 50px;
        width: 45%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
        border-radius: 6px;
        background-color: #FF1493;
        color: white;
    }

    .choosing-user-radio {
        display: inline-grid;
    }

    .label-time-selected {
        display: flex;
        padding: 16px 24px;
        flex-direction: column;
        align-self: stretch;
        border-radius: 6px;
        background: #F8F8F8;
    }

    .title-choosing {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
    }

    .choosing-doctor-list {
        height: 60%;
        overflow: auto;
        display: flex;
        padding: 15px;
        flex-direction: column;
        align-self: stretch;
        border-radius: 6px;
    }

    /* .upper-name {
        color: #565C67;
        font-size: 10px;
        line-height: 16px;
    } */

    .lower-name {
        color: #2E3138;
        font-size: 14px;
        line-height: 16px;
        padding: 10px;
    }

    .choosing-doctor-list {
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
    }

    .select-book-time {
        align-items: center;
    }

    .select-book-time2 {
        display: flex;
        height: auto;
        padding: 0px 12px;
        margin-left: 10px;
        justify-content: center;
        align-items: center;
        gap: 0px;
        border-radius: 0px;
        border-left: 1px solid #ECEDEF;
    }

    .icon-doc2 {
        padding: 5px;
    }

    .border-select-time {
        display: flex;
        height: auto;
        padding: 0 0 0 20px;
        align-items: center;
        gap: 20px;
        flex: 1 0 0;
        border-radius: 0px;
        border: 1px solid #ECEDEF;
    }

    .choosing-doctor-list>.ant-radio-group {
        width: auto;
    }

    .ant-modal-wrap .chooseDoctorModal {
        margin-top: -50px;
    }
}

/* mobile */
@media (max-width: 350px) {
    .dropdown-selection {
        max-width: 60%;
    }
}


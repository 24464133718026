.tabs_register_address_list > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    width: 100% !important;
    background: #f2f3f5;
    border-radius: 60px !important;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border: 2px solid #f2f3f5;
}
.tabs_register_address_list>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab {
    width: 33.33333% !important;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.00) !important;
}
.tabs_register_address_list>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-active {
    border-radius: 60px !important;
    background: #ffffff !important;
}
.tabs_register_address_list>.ant-tabs-card .ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
    border-bottom-color: #f2f3f5 !important;
}
.tabs_register_address_list > .ant-tabs-nav::before {
    border-bottom: none !important;
}
.tabs_register_address_list>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list span {
    color: #707070;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}
.tabs_register_address_list>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-active span {
    color: #308AE6;
}
.tabs_register_address_container>.ant-row {
    padding-bottom: 20px;
}

.tabs_register_address_container_modal>.ant-row {
    padding-bottom: unset;
}
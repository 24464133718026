.message {
  display: flex;
  gap: 6px;
  margin: 16px 0;
}
.message span {
  color: #2e3138;
  line-height: 20px;
}

.pre-line {
  white-space: pre-line;
}

@media (max-width: 768px) {
  .message {
    margin-top: 0;
  }
}
.infoHos .ant-table-cell:nth-child(2) span.ant-select-selection-item {
    display: flex;
    justify-content: left;
}
.infoHos .ant-table-cell:nth-child(2) {
    justify-content: end;
    text-align: end;
}

.infoHosCard .ant-table-row:nth-child(1) .ant-table-cell:nth-child(2) {
    padding-left: 0;
    padding-right: 0;
}

.infoHos div.ant-table div.ant-table-title {
    padding-left: 0;

    color: #565C67;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
}
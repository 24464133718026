/* button-css */
.buttonPt03-login {
    background-color: #FF1493;
    color: #FFFFFF;
    width: 100%;
    height: 60px;
}

.buttonPt03 {
    color: #FF1493;
    width: 100%;
    height: 60px;
    border-color: #FFC7E3;
}
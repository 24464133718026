.prescriptionReceiveNew {
    border: 1px solid #F2F3F5;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    padding: 24px 40px 48px 40px;
    margin-bottom: 20px;
}

.prescriptionReceiveNew .title {
    color: #565C67;
    font-size: 17px;
}

.prescriptionReceiveNew .radioOptions {
    display: flex;
    gap: 24px;
    width: 100%;
}

.prescriptionReceiveNew .prescriptionInfo {
    padding: 20px 24px;
    border-radius: 6px;
    background: #F2F3F5;
}

.prescriptionReceiveNew .prescriptionInfoTitle {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 15px;
    height: 32px;
}

.prescriptionReceiveNew .prescriptionInfoButton .button {
    background-color: #FF1493; 
    color: #FFFFFF;
    width: 180px;
    cursor: pointer;
}

.prescriptionReceiveNew .prescriptionInfoButton .button:hover {
    color: #ffffff;
}
.modalNewAddress .titleModalContent {
    font-size: 15px;
    line-height: 32px;
}
/* overwrite antd-css */
.ant-modal-wrap .modalNewAddress {
    width: 800px !important;
    height:  90vh !important;
}

.wrapModalNewAddress .ant-modal-body{
    padding-right: 10px;
}

.modalNewAddress .ant-modal-body{
    padding-right: 10px;
}

.modalNewAddress .ant-modal-body {
    border-radius: 6px;
    max-height: 85vh;
}
.modalNewAddress>.ant-modal-content>.ant-modal-header {
    border-bottom: 1px solid #D0D2D8;
    height: 60px;
    display: flex;
    align-items: center;
}
.modalNewAddress .ant-modal-close {
    margin: 20px 12px 0;
}
.modalNewAddress .ant-modal-content {
    padding: 20px 24px !important;
}

.prescriptionInfo .ant-card {
    border-radius: 6px;
    border: 1px solid var(--color-alias-border-light, #F2F3F5);
    box-shadow: 0px 0px 4px 0px rgba(46, 49, 56, 0.10), 0px 2px 2px 0px rgba(46, 49, 56, 0.10);
}

.prescriptionInfo .ant-card .ant-card-head {
    padding: 8px 8px 8px 16px;
}
.prescriptionInfo .ant-card .ant-card-body {
    background: #f8f8f8;
    padding: 16px 16px 24px 16px;
    min-height: 260px;
}


/* < PC */
@media (max-width: 1200px)  { 
    .modalNewAddress .ant-modal-body {
        max-height: 80vh;
    }
}

/* mobile */
@media (max-width: 768px) {
    .prescriptionReceiveNew {
        padding: 16px 16px 48px 16px;
    }

    .prescriptionReceiveNew .title {
        text-align: center;
    }

    .prescriptionReceiveNew .prescriptionInfo {
        padding: 24px 24px 40px 24px;
        border-radius: 6px;
        background: #F2F3F5;
    }

    .prescriptionReceiveNew .prescriptionInfo .prescriptionInfoButton {
        width: 100%;
    }

    .prescriptionReceiveNew .prescriptionInfoButton .button {
        height: 60px;
        width: 100%;
    }

    /* overwrite antd-css */
    .ant-modal-wrap .modalNewAddress {
        width: 400px !important;
    }
}
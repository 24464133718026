.box-search {
    display: flex;
    padding: 24px 24px 40px 24px;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-wide, 36px);
    align-self: stretch;

    border-radius: 0px;
    border: 1px solid #F2F3F5;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
}

.box-search-title {
    margin: 0px 24px;
}

.box-search-title-lv1 {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    margin-bottom: 12px;
}

.box-search-title-lv1>span {
    color: #565C67;
    text-align: center;
    font-size: 16px;
    line-height: normal;
}

.box-search-title-link {
    display: flex;
    justify-content: center;
}

.box-search-title-link>a {
    color: #308AE6;
    font-size: 16px;
    line-height: normal;
}

.box-search-input {
    width: 100%;
}

.box-search-input>div {
    margin: 0px 96px;
}
.box-search-input-group {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    gap: 10px;

    border-radius: 6px;
    background: #F2F3F5;
    position: relative;
}

.input-wrap {
    display: flex;
    flex-direction: row;
    gap: 60px;
}

.input-wrap > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

div .message-error {
    color: red;
}
.search-modal {
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    margin-top: -1em;
    max-width: 684px;
    padding: 30px;
    position: absolute;
    top: 100%;
    z-index: 10;
}

.search-modal .modal-title {
    border-bottom: 1px dotted currentColor;
    color: #404040;
    font-size: 14px;
    margin: 0 0 0.5em;
    padding: 0;
    text-align: left;
}

.search-modal .modal-title .modal-keyword-button {
    background: #1e6097;
    border: 1px solid #1e6097;
    border-radius: 2em;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    margin: 0.75em 0.75em 0 0;
    line-height: 1;
    padding: 0.4em 1.25em;
    white-space: nowrap;
}

.search-modal .modal-title .i-block {
    display: inline-block;
    margin: 0.5em 0;
}

.search-modal .modal-heading {
    color: #404040;
    font-size: 14px;
    margin: 0.5em 0 0;
    text-align: left;
}

.search-modal .modal-keyword-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 170px;
    overflow-x: auto;
}

.search-modal .modal-keyword-item, .modal-keyword-item1 {
    background: #edf4fc;
    border: 1px solid #1e6097;
    border-radius: 2em;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    margin: 0.75em 0.75em 0 0;
    line-height: 1;
    padding: 0.4em 1.25em;
    white-space: nowrap;
    transition: 0.5s all;
}

#modal-subject, #modal-subject-none {
    transform: translateX(370px);
}

#modal-subject {
    max-height: 230px;
}

.search-modal-none {
    display: none;
}

.input-department, .input-region {
    width: 100%;
    height: 58px;
    margin-right: 20px;
    font-size: 18px;
}

.input-clinic {
    width: 100%;
    height: 58px;
    font-size: 18px;
}

@media (max-width: 768px) {
    .box-search {
        display: flex;
        padding: 40px 16px;
        flex-direction: column;
        align-items: center;
        gap: var(--gap-wide, 36px);
        align-self: stretch;

        border-radius: 0px;
        border: 1px solid #F2F3F5;
        background: #FFF;

        box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);

        width: auto;
        margin: 0;
    }
    .box-search-title {
        display: flex;
        padding: 0px;
        flex-direction: column;
        align-items: center;
        gap: var(--gap-small, 12px);
        align-self: stretch;

        border-radius: 0px;
        margin: 0;
    }
    .box-search-input-group {
        display: flex;
        padding: 20px;
        flex-direction: column;
        justify-content: flex-end;
        gap: 20px;
        align-self: stretch;

        width: auto;
        margin: 0;
    }

    .box-search-input>div {
        margin: 0;
    }

    .input-wrap {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    #modal-area.search-modal {
        top: 46%;
    }
    #modal-subject.search-modal {
        transform: translateX(0px);
        top: 73%;
    }
}
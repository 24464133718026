.tableContent th, .tableContent td {
    border: 1px solid #f0f0f0;
}
.tableContent-body .minusData {
    background: #F7F7F8;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
} 
.tableContent-footer {
    height: inherit;
    width: 100%;
    margin: 0 !important;
    justify-content: start;
}
.tableContent-footer .footerMark img {
    margin-right: 8px;
}

/* overwrite antd-css */
.tableContent-body .ant-table-thead > tr > th .sunday {
    color: #e42993;
}
.tableContent-body .ant-table-thead > tr > th .saturday {
    color: #308AE6;
}
.tableContent-body .ant-table-thead > tr > th,
.tableContent-body .ant-table-tbody > tr > td {
    text-align: center !important;
}
.tableContent-body .ant-table-thead > tr > th[scope='col'] {
    background: #F2F3F5 !important;
}
.tableContent-body .ant-table-thead > tr > td {
    background: #fff;
}
.tableContent-body .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
}
@media (max-width: 768px) {
    .tableContent {
        width: 100%;
    }
    .tableContent>.ant-table-wrapper .ant-table-header tr {
      padding: 0 !important;
    }
    .tableContent>.ant-table-wrapper .ant-table-header th {
        padding: 0 !important;
        font-size: 4px !important;
    }
    .tableContent>.ant-table-wrapper .ant-table-header th>div>div>div {
        font-size: 4px !important;
    }
    .tableContent>.ant-table-wrapper .ant-table-header tr>th>div {
        padding: 0 !important;
        font-size: 4px !important;
    }
    .tableContent>.ant-table-wrapper .ant-table-body tr>td {
        padding: 0 !important;
        right: 0 !important;
        font-size: 4px;
    }
    .tableContent>.ant-table-wrapper .ant-table-body tr>td img {
        width: 10px;
    }
    .tableContent-footer>div {
        padding: 0 !important;
    }
    .tableContent>.ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        position: relative;
    }
    .tableContent>.ant-table-wrapper .ant-table-header colgroup col:nth-child(1) {
        width: 20px !important;
    }
    .tableContent>.ant-table-wrapper .ant-table-body colgroup col:nth-child(1) {
        width: 20px !important;
    }
    .tableContent>.ant-table-wrapper .ant-table-header colgroup col:nth-child(17) {
        width: 20px !important;
    }
    .tableContent>.ant-table-wrapper .ant-table-body colgroup col:nth-child(17) {
        width: 20px !important;
    }
}
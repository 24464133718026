.introduce-left {
  padding: 0 20px;
}
.starrating {
  margin: 20px 0;
}
.clinicName {
  color: #6c747b;
  font-size: 24px;
  line-height: normal;
}
.rating > div {
  display: flex;
}
.rating > div {
  display: flex;
  padding-right: 16px;
}
.rating > div img {
  padding-right: 3px;
}
.rating > div:nth-child(2) span {
  color: #565c67;
  font-size: 14px;
  line-height: normal;
}
.rating > div:nth-child(3) span {
  color: #2459a8;
  font-size: 14px;
  line-height: normal;
}

@media (max-width: 768px) {
  .introduce-left {
    padding-left: 0;
  }
}
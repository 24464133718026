.cfRegisterCard .confirmBtn {
    background: #FF1493;
    width: 300px;
    color: #FFFFFF;
    height: 60px;
    font-weight: 700;
    font-size: 16px;
}

.cfRegisterCard  .confirmBtn:hover {
    background: #FF1493 !important;
    color: #FFFFFF !important;
}

.cfRegisterCard .ant-modal-header {
    border-bottom: none !important;
}

.cfRegisterCard .ant-modal-title {
    font-weight: 700;
    font-size: 17px;
    color: #565C67;
    border-bottom: solid 1px #D0D2D8;
    padding: 17px 0;
    margin-top: 7px;
}

.cfRegisterCard .info1 {
    font-size: 14px;
    font-weight: 700;
    color: #2E3138;
}

.cfRegisterCard td {
    height: 56px !important;
    border-bottom: 1px solid #E3E5E8;
    padding-bottom: 0;
    vertical-align: bottom;
}

.cfRegisterCard tr td:nth-child(2) {
    text-align: right;
    color: #565C67;
    font-weight: 700;
}
.cfRegisterCard tr td:nth-child(1) {
    color: #2E3138;
}

.cfRegisterCard .ant-modal-content{
    width: 800px;
    height: 670px;
    padding: 0 40px !important;
    left: calc(50% - 400px);
    border-radius: 0 !important;
}

.cfRegisterCard .ant-modal-content .ant-modal-body{
    padding: 40px 0 !important;
}

.cardImg{
    width: 70px;
    height: 48px;
    margin-right: 8px;
    margin-bottom: 40px;
    margin-top: 20px;
}

.cfRegisterCard .ant-modal-footer{
    margin-top: 0 !important;
}
.prescription-title {
    color: #565C67;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
}

.inline-row {
    display: inline-flex;
    grid-gap: 20px;
}

.prescription-info-label-first input {
    height: 58px;
}

.prescription-info-label-first {
    width: 160% !important;
}

.prescription-info-label .prescription-info-input {
    margin-top: 8px;
}

.prescription-info-label .ant-tag {
    bottom: 2px;
    left: 3px;
}

.checkbox-div {
    display: contents;
    align-items: center;
}

.checkbox-label {
    margin-left: 8px;
}

.text-div {
    margin-top: 20px;
}

.prescription-row {
    margin-bottom: 20px;
}

.sub-text {
    margin-bottom: 20px;
}

.radio-group {
    margin-bottom: 20px;
}

.prescription-info {
    padding: 24px;
    border-radius: 6px;
    background: #F2F3F5;
}

.prescription-info-text {
    margin-bottom: 10px;
}
.prescription-info-input {
    width: 100%;
}

.prescription-address {
    padding: 24px;
    border-radius: 6px;
    background: #F2F3F5;
}
.prescription-address-modal {
    padding: unset;
    background: #ffffff;
}

.prescription-address>span {
    color: #2E3138;
    font-family: Meiryo;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.measure-biological-data>div>div>.ant-form-item {
    width: 90%;
}

.measure-biological-data>div>div>.ant-form-item>div>div>div>div>input {
    display: flex;
    height: 58px;
    padding: var(--gap-none, 0px) var(--gap-small, 12px);

    align-items: center;
    gap: var(--gap-x-small, 8px);

    align-self: stretch;
}

.prescription-info-button>.ant-btn,
.prescription-info-button>.ant-btn:hover {
    background: #ff1493;
    color: #ffffff !important;
    font-size: 16px;
    height: 60px;
    line-height: 16px;
    border-radius: 6px;
    border: none;
    padding: 8px 24px;
}

.prescription-info-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 36px;
}

.prescription-info-search-button,
.prescription-info-search-button:hover {
    color: #FF1493 !important;
    top: 16px;
    height: 40px;
    flex: 0;
    border-color: #FFC7E3 !important;
}


.prescription-address .prescription-info-row {
    display: flex;
    align-items: center;
    width: auto;
    grid-gap: 20px;
}

.prescription-info-label-first>span:nth-child(1) {
    padding-right: 8px;
}
.prescription-address .title-label {
    margin-bottom: 8px;
}
.prescription-address .prescription-info-button, .prescription .prescription-info-button{
    justify-content: center;
    align-items: center;
}

.prescription-address .prescription-info-button .buttonCancel, .prescription .prescription-info-button .buttonCancel{
    height: 60px;
    background: #8B969E;
    color: #ffffff;
    width: 100%;
}

.prescription-address .prescription-info-button .buttonCancel:hover, .prescription .prescription-info-button .buttonCancel:hover {
    color: #ffffff;
}

.prescription-address .prescription-info-button .buttonAccept, .prescription .prescription-info-button .buttonAccept {
    height: 60px;
    background: #FF1493;
    color: #ffffff;
    cursor: pointer;
    margin-top: unset;
    width: 100%;
}

.prescription-address .search-postcode  .ant-select-in-form-item  {
    height: 58px !important;
}

.prescription-info-prefectures {
    width: 110%;
}

@media (max-width: 768px) {
    .prescription-info-label-first {
        width: 100% !important;
    }
    .prescription-info-prefectures {
        width: 100% !important;
    }
    .prescription-info-button {
        flex-direction: column-reverse;
    }
}
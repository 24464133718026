.step-by-step {
    width: 75%;
}

.frame-arrows {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #F8F8F8;
    height: 150px;
    width: 100%;
    margin-bottom: 24px;
}

.text {
    display: flex;
    height: 32px;
    align-items: center;
    color: #6C747B;
    font-size: 20px;
    line-height: normal;
    margin-top: 36px;
}

.arrow-steps {
    display: flex;
    height: 50%;

}

.arrow-steps>div {
    width: 100%;
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

.arrow-steps .step0 {
    background-image: url(../../../public/images/Component0.svg);
}

.arrow-steps .step1 {
    background: url(../../../public/images/Component1.svg);
}

.arrow-steps .step2 {
    background: url(../../../public/images/Component2.svg);
}

.arrow-steps .step3 {
    background: url(../../../public/images/Component2.svg);
}

.container-info .schedu-info {
    flex-shrink: 0;
    gap: 40px;
    align-self: stretch;
}
.container-info .payment, .container-schedu-info .payment {
    margin: 40px 0 20px 0;
}
.container-info .payment>span, .container-schedu-info .payment>span {
    color: #565C67;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.container-step {
    display: inline;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-step .ant-row .ant-col button {
    height: 60px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
}

.container-step .ant-row .ant-col .next-button-step {
    border-radius: 6px;
    background: #FF1493;
    width: -webkit-fill-available;
    height: 60px;
    color: #FFFFFF;
    margin-top: 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    cursor: pointer;
}

.container-step .ant-row .ant-col .button-disabled {
    border-radius: 6px;
    background: #FFC7E3;
}

.container-step .ant-row .ant-col .next-button-step span {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
}

.container-step .ant-row .ant-col .prev-button-step {
    margin-top: 20px;
    border-radius: 6px;
    background: #F0F5F8;
    width: -webkit-fill-available;
}

.container-step .ant-row .ant-col .prev-button-step img {
    padding-right: 12px;
}

.container-step .ant-row .ant-col .prev-button-step span {
    color: #6C747B;
    font-size: 16px;
    line-height: 16px;
}

.container-schedu-info {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
}

.schedu-info-table .ant-table-wrapper {
    border: 1px solid #F2F3F5;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    padding: 16px 24px 40px 24px;
}

.schedu-info-table .ant-table-cell:nth-child(1) span {
    color: #2E3138;
    font-size: 16px;
    line-height: 16px;
}

.schedu-info-table .ant-table-cell:nth-child(2) span {
    color: #565C67;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    justify-content: right;
}

.schedu-regis {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 20px;
}

.schedu-regis button {
    height: 60px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    flex: 1 0 0;
}

.schedu-regis button:nth-child(1),
.schedu-regis button:nth-child(2) {
    border-radius: 6px;
    background: #FF1493;
}

.schedu-regis button:nth-child(1),
.schedu-regis button:nth-child(2) span {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
}

.schedu-regis>button:hover {
    color: #FFFFFF !important;
}

.container-info.active {
    display: none;
}

.container-step.active button:nth-child(1) {
    margin-top: 0px;
}

.title-calendar-step {
    visibility: hidden;
}

.info-step2, .info-step3 {
    display: none;
}

.print-button-step, .print-button-step:hover {
    width: 100%;
    height: 60px;
    border-radius: 6px;
    background: #FFF2F9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF1493 !important;
    border: unset !important;
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
}

.print-button-step img {
    padding-right: 12px;
}

.temperature-print, .form-print {
    display: none;
}
@media (max-width: 1200px) {

    .arrow-steps .step0 {
        background-image: url(../../../public/images/Component0-mobile.svg);
    }

    .arrow-steps .step1 {
        background: url(../../../public/images/Component1-mobile.svg);
    }

    .arrow-steps .step2, .arrow-steps .step3 {
        background: url(../../../public/images/Component2-mobile.svg);
    }

    .arrow-steps {
        width: 100%;
        height: 100%;

    }

    .frame-arrows {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px;
        background: #FFF;
        width: 100%;
        margin-bottom: 0px;
    }

    .text {
        justify-content: center;
        margin-bottom: 0px;
    }

    .text-2 {
        display: none;
        margin: 0px;
    }

    .title-calendar-step .text-3 {
        text-align: center;
        margin: 0px;
        color: #565C67;
        font-size: 16px;
        line-height: normal;
        margin: 16px 0 24px 0;
    }

    .title-calendar-step {
        visibility: visible;
        margin: 20px;
    }

    .step-by-step {
        width: 90%;
    }

    .container-step-calendar .ant-row {
        display: flex;
        width: inherit;
        flex-wrap: wrap;
    }

    .container-step-calendar {
        /* position: fixed;
        width: -webkit-fill-available;
        width: 75%; */
    }

    .container-step-calendar {
        flex-direction: column;
        align-items: flex-start;
        z-index: 666;
    }

    .container-step .ant-row .ant-col button {
        height: 40px !important;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none !important;
    }

    .container-step-calendar {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .container-step-calendar .ant-row .ant-col button {
        margin-top: 10px !important;
    }

    /* .container-step .ant-row .ant-col button {
        margin-top: 0px;
        width: 75%;
    } */
    /* .wrapper-content {
        padding-bottom: 100px;
    } */

    .form-step {
        display: flex;
        flex-wrap: wrap;
    }

    .main-step {
        order: 2;
    }

    .info-step {
        order: 1;
        padding-bottom: 20px;
    }

    .info-step2, .info-step3 {
        display: inline;
        text-align: -webkit-center;
        order: 3;
    }

    .info-step .container-step-normal {
        display: none !important;
    }

    .info-step .container-schedu-info {
        display: none !important;
    }

    .scroll-to-top {
        bottom: 130px;
    }

    .container-info .payment>span, .container-schedu-info .payment>span {
        display: flex !important;
        justify-content: center !important;
    }

    .infoHos div.ant-table div.ant-table-title {
        display: flex !important;
        justify-content: center !important;
    }

    @media (min-width: 768px) {
        .container-step-calendar {
            flex-direction: row;
        }

        .row-step {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90%;
        }

        .container-step button {
            padding: 0;
        }

        .container-step .ant-row .ant-col .next-button-step {
            margin-top: 20px;
        }

        .ant-row .ant-col button {
            /* flex: 0 0 40%; */
            margin-right: 2px;
        }

        .ant-row .ant-col button:last-child {
            margin-right: 0;
        }

        .scroll-to-top {
            bottom: 160px;
        }
    }
    .container-schedu-info>button, .container-schedu-info>.schedu-regis {
        display: none !important;
    }
    .form-step {
        display: flex;
    }
    .info-step2 {
        order: 2;
    }
    .main-step {
        order: 3;
    }
    .info-step1 {
        order: 1;
    }
    .info-step3 {
        order: 4;
    }
    @media (max-width: 768px) {
        .info-step3 {
            padding-bottom: 120px;
        }
    }
}

@media print {
    .degree-select-form, .info-step3, .payment-info-container, .payment, .img-icon, .questionTemplate>.ant-tag, .frame-arrows, .cardPayment, .scroll-to-top, .footer, .header, .container-schedu-info .ant-btn {
        display: none !important;
    }
    .temperature-print{
        display: inline;
        padding-left: 30px !important;
    }
    .form-print {
        box-sizing: border-box;
        margin: 0;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        list-style: none;
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        background-color: #ffffff;
        background-image: none;
        border-width: 1px;
        border-style: solid;
        border-color: #d9d9d9;
        border-radius: 6px;
        word-wrap: break-word;
    }
    .form-step {
        display: flex;
    }
    .info-step2 {
        order: 2;
    }
    .main-step {
        order: 3;
    }
    .info-step1 {
        order: 1;
    }
    .infomation .questionTemplate, .questionRow>div {
        padding: 0;
    }
    .form-print { 
        min-height: 50px;
        margin-left: 16px;
        border-radius: 6px;
        padding: 10px;
    }
    .text {
        margin-top: 0px;
    }
    
    .infoHos .ant-table-wrapper {
        border: 0px !important;
        background: #FFF;
        box-shadow: none !important;
        padding: 0px !important;
    }
    
    .schedu-info-table .ant-table-wrapper {
        border: 0px !important;
        background: #FFF;
        box-shadow: none;
        padding: 0 !important;
    }
    
    .biological-data-container {
        padding: 0 !important;
        border: 0 !important;
        background: #FFF;
        box-shadow: none !important;
        /* page-break-before: always !important; */
    }
    
    .receptioninfo {
        border: 0 !important;
        background: #FFFFFF;
        box-shadow: none !important;
        /* page-break-before: always !important; */
    }
    
    .prescriptionReceiveConfirm {
        padding: 0 !important;
    }
    
    .infomation {
        margin: 0px !important;
    }
    
    .info-step2 {
        padding-bottom: 10px !important;
    }
    
    .ant-table-wrapper .ant-table-tbody >tr >td{
        padding-left: 0px !important;
    }
    
    .infoHos .ant-table-wrapper .ant-table-tbody >tr {
        padding-left: 0px !important;
    }
    
    .prescriptionReceiveConfirm>.prescription-row>.prescription-title-row>.prescription-title {
        justify-content: center !important;
        align-items: center !important;
        display: flex !important;
        padding-top: 20px;
    }
    
    .infomation>div {
        margin-bottom: 0px !important;
    }
    
    .prescription-row {
        row-gap: 10px !important;
    }
    
    .layout>div:nth-child(2) {
        height: 20px !important
    }
    
    .infomation .questionTemplate {
        padding: 0px !important;
    }
    
    .wrapper-content {
        padding-bottom: 0px !important;
    }
    
    .container-schedu-info {
        margin: 0px !important;
    }
    
    .infomation>div>div {
        margin-bottom: 25px !important;
    }
}

@media (max-width: 1600px) {
    /* overwrite antd-css */
    .schedu-info .ant-table-row {
        display: flex;
        flex-direction: column;
    }
    .schedu-info .ant-table-row .ant-table-cell:nth-child(1) {
        border-bottom: unset;
        padding-bottom: 0;
    }
    .schedu-info .ant-table-row .ant-table-cell:nth-child(2) {
        display: flex;
        justify-content: start !important;
        text-align: start !important;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    
}

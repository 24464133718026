.text-area-regist-medical-tab1 .ant-input-textarea-show-count .ant-input-suffix .ant-input-data-count{

}

.tab1-element {
    padding-bottom: 20px;
}

.tab1-element label, .tab1-element-input label {
    font-size: 15px !important;
}
.header-reservation {
    /* display: flex;
    align-items: center; */
    margin: 40px 40px 24px 40px;
}
.header-reservation>.ant-row>.ant-col:nth-child(2) {
    display: flex;
    align-items: center;
}
.header-reservation>.tabbarSearch>.searchDate>.ant-form-item {
    margin-bottom: 0 !important;
}
.reservation_checkbox>.ant-row>.ant-checkbox-group>.ant-checkbox-wrapper {
    padding-left: 32px;
}
.header-reservation>.ant-row>.ant-col>.reservation_checkbox>.ant-checkbox-group>.ant-checkbox-wrapper>span {
    color: #2E3138;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.header-reservation>.ant-row>.ant-col>.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}
.header-reservation>.ant-row>.ant-col>.header-right>div:not(.header-reservation .header-right>div:nth-child(3)) {
    padding-right: 20px;
}
.header-reservation>.ant-row>.ant-col>.header-right>div>span:nth-child(1) {
    color: #565C67;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}
.header-reservation>.ant-row>.ant-col>.header-right>div>span:nth-child(2) {
    color: #2E3138;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
}
.reservations {
    margin-top: 24px;
    min-width: 1366px;
}

.reservations .ant-table-thead .ant-table-cell>span {
    color: #565C67;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}
.reservations .ant-table-tbody .ant-table-cell {
    color: #2E3138;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}
.reservations .ant-table-thead .ant-table-cell .sort {
    position: absolute;
    top: 23px;
    right: 12px;
    cursor: pointer;
}
.reservations .ant-table-thead>tr>.ant-table-cell:nth-child(2)>span {
    display: flex;
    align-items: center;
}
.reservations>.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>th {
    height: 77px;
}
.reservations>.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr>td {
    height: 77px;
}
.header-reservation>.ant-row>.ant-col>.header-right>.active_menu {
    cursor: pointer;
}
.reservations>.ant-table-wrapper .ant-table-thead .ant-table-cell .sort {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -22px;
    height: 100%;
}
.reservations>.ant-table-wrapper .ant-table-thead .ant-table-cell .sort>div {
    display: flex;
}
.reservations>.ant-table-wrapper .ant-table-tbody .ant-table-cell .active_row {
    cursor: pointer;
}
.reservations>.ant-table-wrapper .ant-table-thead>tr>th:first-child {
    min-width: 36px !important;
    text-align: center;
}
.reservations>.ant-table-wrapper .ant-table-thead .stt {
    min-width: 36px;
}
.reservations>.ant-table-wrapper .ant-table-thead .time {
    min-width: 200px;
}
.reservations>.ant-table-wrapper .ant-table-thead .status {
    min-width: 136px;
}
.reservations>.ant-table-wrapper .ant-table-thead .user_name {
    min-width: 317px;   
}   
.reservations>.ant-table-wrapper .ant-table-thead .sex {
    min-width: 136px;
}
.reservations>.ant-table-wrapper .ant-table-thead .treatment_menu_name {
    min-width: 317px;   
}
.reservations>.ant-table-wrapper .ant-table-thead .smedical_record_storageex {
    min-width: 56px;
}
.reservations>.ant-table-wrapper .ant-table-thead .question {
    min-width: 56px;
}
.reservations>.ant-table-wrapper .ant-table-thead .clinic_user_name {
    min-width: 317px;
}
.reservations>.ant-table-wrapper .ant-table-thead .icon {
    min-width: 36px;   
}
.reservationsTbl .ant-table-content {
    overflow: auto;
    height: 70vh;
    border-collapse: collapse;
    overflow-x: hidden;
}

.reservationsTbl .ant-table-content thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.reservationsTbl .ant-table-content::-webkit-scrollbar {
    width: 6px;
}

.reservationsTbl .ant-table-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ECEDEF;
    border-radius: 10px;
    width: 8px !important;
}

.reservationsTbl .ant-table-content::-webkit-scrollbar-thumb {
    background: #D5D8DC;
    border-radius: 10px;
}
@media (max-width: 1200px) {
    .reservations {
        margin-left: 20px !important;
    }
    .reservation_checkbox {
        margin-top: 20px;
    }
    .reservations .ant-table-thead .ant-table-cell .sort {
        top: 22px
    }
}

@media (max-width: 768px) {
    .reservations {
        margin-left: 40px !important;
    }
}
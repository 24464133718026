.modalSuccsess {
    font-family: 'Meiryo';
}
.bodyModalSuccess {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.bodyModalSuccess .content-title {
    color: #565C67;
    text-align: center;
    font-size: 17px;
    line-height: normal;
}
.bodyModalSuccess .notifi {
    display: flex;
    padding: 8px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
}
.bodyModalSuccess .notifi>img {
    padding-right: 6px;
}
.bodyModalSuccess .notifi>span{
    color: #FF5E67;
    font-family: 'Zen Kaku Gothic New';
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    margin: 2px 0;
}
.bodyModalSuccess .message {
    color: #2E3138;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}
/* overwrite antd-css */
.ant-modal-wrap .modalSuccsess {
    width: 640px !important;
}

@media (max-width: 768px) {
    /* overwrite antd-css */
    .ant-modal-wrap .modalSuccsess {
        width: 400px !important;
    }
}
.modalSuccsess-cancel {
    background-color: #F0F5F8;
    color: #6C747B;
    width: 100%;
    height: 60px;
    font-size: 16px;
}
.modalSuccsess-cancel img {
    margin-right: 12px;
}
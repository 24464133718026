.confirm-wrapper {
    top: 60px;
}

.confirm-wrapper .ant-modal-header {
    border-bottom: 1px solid #D0D2D8;
    height: 60px;
    margin: 8px 12px 0 12px;
    display: flex;
    align-items: center;
}

.confirm-wrapper .confirm-content {
    margin-top: 32px;
}

.confirm-wrapper .ant-modal-header .ant-modal-title {
    font-family: 'Meiryo';
}

.confirm-wrapper .title-body>div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.confirm-wrapper .body-content {
    margin: 8px;
    padding: 24px;
    border-radius: 6px;
    background: #F8F8F8;
}

.confirm-wrapper .title-body span {
    color: #2E3138;
    text-align: center;
    font-size: 14px;
    line-height: normal;
}

.confirm-wrapper .info-user {
    margin-bottom: 16px;
}

.confirm-wrapper .info-user>.ant-row:nth-child(1) {
    margin-bottom: 3px;
}

.confirm-wrapper .title-info {
    color: #565C67;
    font-size: 17px;
    line-height: normal;
}

.confirm-wrapper .content-info {
    color: #565C67;
    font-size: 14px;
    line-height: 16px;
}

.confirm-wrapper .btn-submit {
    margin-bottom: 20px;
}
.header-container {
    position: fixed;
    width: 100vw;
    z-index: 800;
}
.header {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    height: 80px;
    box-shadow: 0px 8px 15px 2px rgba(46, 49, 56, 0.10), 0px 0px 2px 0px rgba(46, 49, 56, 0.20);
    padding: var(--gap-medium, 24px) 200px;
}
.ichashoku img {
    width: 175px;
    height: 38px;
}
.header-container .dropDownMenu {
    display: none;
}

/* mobile & tablet */
@media (max-width: 992px) {
    .header {
        padding: 20px 10px 20px 20px;
    }
    .header-container .dropDownMenu {
        display: block;
        border-top: 1px solid var(--color-alias-border-light, #F2F3F5);
    }
    .ichashoku img {
        width: 120px;
        height: 38px;
    }
}
.scroll-to-top {
    position: fixed;
    bottom: 78px;
    right: 60px;
    animation: fadeIn 200ms ease-in-out 1s both;
    cursor: pointer;
    z-index: 998;
} 

/* mobile-css */
@media (max-width: 768px) {
    .scroll-to-top {
        right: 40%;
    }
}
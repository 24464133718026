.settingPassword-wrap {
    padding: var(--gap-wide, 36px) 240px 240px 240px;
    width: 100vw;
}
.settingPassword .title {
    color: #6C747B;
    font-size: 20px;
}
.settingPassword .fieldName .name {
    color: #565C67;
    font-size: 17px;
}
.settingPassword .notice {
    margin-bottom: 24px;
    margin-top: 12px;
}
.settingPassword .error{
    margin-bottom: 24px;
    margin-top: 12px;
    color: red !important;
}
.settingPassword .fieldInput {
    border-radius: var(--radius-slightly-rounded, 6px);
    border: 1px solid var(--color-alias-border-light, #F2F3F5);
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    padding: 40px 40px 48px;
}
.settingPassword .fieldButton { 
    padding-left: 80px;
}
.settingPassword .fieldButton button { 
    width: 100%;
    min-width: 250px;
}
.settingPassword .suggest { 
    margin-bottom: 24px;
}
.settingPassword .buttonRegist {
    margin-bottom: 20px;
    width: 100%;
    height: 60px;
    background-color: #FF1493;
    border: none;
    color: #FFFFFF;
}
.settingPassword .buttonRegist-disable {
    background-color: #FFC7E3 !important;
    color: #FFFFFF !important;
}
.settingPassword .buttonCancel {
    background-color: #F0F5F8;
    color: #6C747B;
    width: 100%;
    height: 60px;
    font-size: 16px;
}
.errorPasswordMatched {
    color: #ff4d4f;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

/* overwrite antd-css */
.settingPassword .fieldInput .ant-input-password {
    width: 70%;
    height: 60px;
}

/* mobile-css */
@media (max-width: 768px) {
    .settingPassword-wrap {
        padding: 60px var(--gap-medium, 24px) 200px var(--gap-medium, 24px);
    }
    .settingPassword .title,
    .settingPassword .fieldName
     {
        text-align: center;
    }
    .settingPassword-notice {
        margin: 20px 0;
    }
    .settingPassword .fieldInput {
        border: none;
        box-shadow: none;
        padding: 0;
    }
    .settingPassword .fieldButton { 
        margin-top: 40px;
        padding-left: 0;
    }
    .buttonSettingPw-regis {
        margin-bottom: 16px;
    }
    /* overwrite antd-css */
    .settingPassword .fieldInput .ant-input-password {
        width: 100%;
    }
}

/* mobile */
@media (max-width: 992px) and (max-height: 400px) {
    .settingPassword-wrap {
      padding-bottom: 0;
    }
}
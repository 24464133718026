.urlwebsite {
    padding: 16px 24px 36px;
    border-radius: 6px;
    border: 1px solid #F2F3F5;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
}
.urlwebsite .ant-table-cell:nth-child(1) span {
    color: #2E3138;
    font-size: 15px;
    line-height: 16px;
}
.urlwebsite .ant-table-cell:nth-child(2) span {
    color: #565C67;
    line-height: 16px;
    display: flex;
    justify-content: end;
}
.urlwebsite-title {
    display: flex;
    min-width: 200px;
}

@media (max-width: 768px) {
    .urlwebsite {
        padding: 16px 16px 36px;
        border-radius: 0;
    }
    /* overwrite antd-css */
    .urlwebsite .ant-table-row {
        display: flex;
        flex-direction: column;
    }
    .urlwebsite .ant-table-cell:nth-child(2) span {
        justify-content: start;
    }
    .urlwebsite .ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover {
        background-color: #ffffff;
    }
    .urlwebsite .ant-table-wrapper .ant-table-tbody >tr >td {
        border-bottom: none;
        padding: unset;
    }
    .urlwebsite .ant-table-wrapper .ant-table-tbody >tr {
        border-bottom: 1px solid #f0f0f0;
        padding: 12px 8px 8px 8px;
    }
    .urlwebsite .ant-table-wrapper .ant-table-tbody >tr .urlwebsite-value {
        margin-top: 8px;
    }
}
.receptioninfo {
    border: 1px solid #F2F3F5;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    margin-bottom: 20px;
}
.warning {
    display: flex;
    align-items: center;
    margin: 24px 40px 20px 40px;
    padding: 8px 0 8px 0;
}
.warning img {
    padding-right: 6px;
}
.warning span {
    color: #2E3138;
    font-size: 14px;
    line-height: 20px;
}
.infomation {
    margin: 24px 40px 20px 40px;
}
.infomation>div {
    margin-bottom: 40px;
}
.infomation>div>div {
    align-items: end;
    margin-bottom: 16px;
}

.infomation .questionRow {
    margin-left: -30px;
}

.infomation .questionTemplate {
    border-radius: 24px 24px 24px 0px;
    background: #F7F7F7;
    padding: 16px 20px;
    display: inline-block;
}

.infomation .questionTemplate .need {
    margin-right: 0;
}

.infomation>div>div:nth-child(1)>div:nth-child(2) span:nth-child(1) {
    color: #191919;
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
}
.infomation>div>div:nth-child(2)>img {
    padding-bottom: 6px;
}
.infomation .ant-select {
    width: 80px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #D0D2D8;
    background: #FFFFFF;
}
.temperature {
    margin: 12px;
}
.infomation .ant-select:nth-child(2) {
    margin-right: 12px;
}

.infomation .ant-select-selection-item,
.ant-select-item-option-content {
    color: #BABDC5;
    font-size: 14px;
    line-height: 16px;
}
.infomation .temperature {
    color: #565C67;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
}
.infomation .ant-input {
    font-size: 14px;
    line-height: 16px;
}
.infomation .ant-form-item-control-input-content input {
    height: 36px;
    border-radius: 6px;
}
.infomation .ant-col>input {
    height: 36px;
}
.insurance>div:nth-child(1) {
    height: 32px;
    align-items: center;
    justify-content: center;
}
.insurance>div:nth-child(1) span {
    color: #565C67;
    font-size: 16px;
    line-height: normal;
}
.insurance>div:nth-child(2) {
    border-radius: 6px;
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0;
}
.insurance>div:nth-child(2) span {
    padding: 0 24px;
    color: #2E3138;
    font-size: 14px;
    line-height: 24px;
}
.insurance>div:nth-child(3) {
    padding-left: 24px;
}
.custom-div {
    margin: 24px 40px 20px 40px;
}

.custom-div>.text-div {
    color: #565C67;
    font-size: 12px;
    line-height: 16px;
}

.prescription-info-prefectures {
    width: 110%;
}

.infomation .img-icon {
    display: flex;
    justify-content: end;
    width: 48px;
    padding-right: 0 !important;
}

/* PC */
@media (max-width: 1600px) {
    .infomation .questionRow {
        margin-left: -10px;
    }
}

/* tablet & PC */
@media (max-width: 1200px)  { 
    .infomation .questionRow {
        margin-left: -30px;
    }
}

@media (max-width: 768px) {
    .infomation .questionRow {
        margin-left: 0;
    }

    .information, .custom-div, .warning, .information-detail {
        margin: 16px 16px 16px 16px !important;
    }
    .inline-row, .prescription-address .prescription-info-row, .prescription-address2 .prescription-info-row {
        display: inline !important;
    }
    .prescription-info-label-first {
        width: 100% !important;
    }
    .prescription-info-prefectures {
        width: 100% !important;
    }
    .prescription-info-search-button {
        top: 0px !important;
        
    }
    .prescription-search-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }

    .prescription-row .medical-detail-receive-radio {
        display: inline-grid;
        row-gap: 10px;
    }
    .prescription-row .revceive-radio-group {
        display: inline-grid;
        row-gap: 10px;
    }
}
.receptioninfo .styles-validator {
    padding-bottom: 22px;
}
.insurance>div>.non_insurance_amount {
    display: flex;
    flex-direction: column;
}
.insurance>.checked_confirm {
    display: block !important;
}
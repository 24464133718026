.regis_address_other {
    padding: 24px;
    border-radius: 6px;
    background: #F2F3F5;
}
.regis_address_other>.ant-row>.warning_line>span {
    color: #2E3138;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.regis_address_other .prescription-info-button {
    justify-content: center;
    align-items: center;
}

.regis_address_other .prescription-info-button .buttonCancel {
    height: 60px;
    background: #8B969E;
    color: #ffffff;
    width: 100%;
}

.regis_address_other .prescription-info-button .buttonCancel:hover {
    color: #ffffff;
}

.regis_address_other .prescription-info-button .buttonAccept {
    height: 60px;
    background: #FF1493;
    color: #ffffff;
    cursor: pointer;
    margin-top: unset;
    width: 100%;
}

.regis_address_other .detail_pharmacy_empty {
    border-radius: 6px;
    background: #F8F8F8;
    margin: 36px 0;
    padding: 24px;
    height: 280px;
}

@media (max-width: 768px) {
    .detailPharmacy-right>.qrcode_container {
        display: none !important;
    }
    .regis_address_other>.ant-row>.warning_line {
        padding-left: 12px;
    }
    .regis_address_other .detail_pharmacy_empty {
        height: 150px;
    }
}
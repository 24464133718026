.header .dropDownMenu-icon {
    display: none;
}
.header .dropDownMenu-icon {
    cursor: pointer;
}

/* mobile & tablet */
@media (max-width: 992px) {
    .header .dropDownMenu-icon
    {
       display: block;
    }
    .spreadMenu {
        display: none;
    }
}
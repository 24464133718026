.uploadCardImage {
    margin-top: 20px;
}

.uploadCardImage .img-card {
    border: 1px solid #F2F3F5;
    justify-content: center;
    display: flex;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    height: 250px;
    padding: 10px;
}

.uploadCardImage .img-card .textLabel {
    position: absolute;
    top: 25px;
    left: 10px;
    font-size: 17px;
}

.uploadCardImage .img-card img {
    width: fit-content !important;
    width: 100%;
}

.uploadCardImage .message-error {
    text-align: center;
    line-height: 40px;
}

.uploadCardImage .img-card .yetUpload .titleAvt {
    margin-top: 12px;
    font-size: 16px;
    line-height: 32px;
}

.uploadCardImage .img-card .yetUpload p {
    text-align: center;
    margin-bottom: 0 !important;
    color: #565C67;
}

.uploadCardImage .wrapper-previewImage {
    display: flex;
    width: 100%;
    align-items: end;
}

.wrapper-previewImage .previewImage {
    height: 100%;
    flex: 1;
}

.wrapper-previewImage .btnClear {
    background: #FFF;
    border-radius: 6px;
    border: 1px solid #FFC7E3;
    color: #FF1493;
}

.wrapper-previewImage .btnClear:hover {
    background: #FFF !important;
    color: #FF1493 !important;
    border: 1px solid #FF1493;
}

/* overwrite antd-css */
.img-card .uploadedImage .ant-upload-select {
    width: 100% !important;
    height: 100% !important;
    max-height: 250px;
    background: #fff !important;
    border: none !important;
}

.img-card .previewImage .ant-upload-list-item-actions {
    display: none;
}

.img-card .previewImage .ant-upload-list-item-container {
    width: 100% !important;
    height: 100% !important;
}

.img-card .previewImage .ant-upload-list-item-thumbnail {
    display: flex !important;
    justify-content: center;
}

.img-card .previewImage .ant-upload-list-item-done::before {
    opacity: 0 !important;
    cursor: pointer;
}

.img-card .previewImage .ant-upload-list-item {
    padding: 0 !important;
}
.img-card .previewImage .ant-upload-list-item-done {
    border: unset !important;
}
.select-search-user {
    position: relative;
    width: 100%;
}

.search-box {
    height: 100%;
}

.search-box .ant-select .ant-select-arrow {
    inset-inline-start: 11px
}

.search-box .ant-select .ant-select-selection-search, .search-box .ant-select .ant-select-selection-placeholder, .search-box .ant-select .ant-select-selection-item {
  padding-left: calc(3rem - 15px) !important;
  font-weight: normal;
}

.user-name-search-box, .info-search-box {
    font-size: 12px;
    padding: 2px;
}

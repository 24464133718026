.tabbarSearch .searchDate {
    display: flex;
    gap: 12px;
    padding-right: 20px;
}
.tabbarSearch .btnDirection  {
    line-height: calc(100% + 0.5em);
    width: 36px;
    text-align: center;
    padding: 0;
}
.tabbarSearch .btnCurrent {
    color: #308AE6;
    background: #F0F8FF;
    width: 80px;
}
.tabbarSearch .searchSelect {
    display: flex;
    gap: 20px;
}

.tabbarSearch .placeHolderDoctor .ant-select-selection-placeholder {
    background-image: url('src/assets/image/doctor3.svg');
    background-repeat: no-repeat;
    padding-left: 30px !important;
    background-position: 0 6px;
}
  
.tabbarSearch .placeHolderTreatmentMenu .ant-select-selection-placeholder {
    background-image: url('src/assets/image/treatmentMenu.svg');
    background-repeat: no-repeat;
    padding-left: 30px !important;
    background-position: 0 6px;
}

/* overwrite antd-css */
.tabbarSearch .ant-btn,
.tabbarSearch .ant-select-selector {
    height: 36px !important;
}

/* < PC */
@media (max-width: 1600px)  { 
    .tabbarSearch .searchDate { 
        padding-bottom: 20px;
    }
}
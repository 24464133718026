.editReservation {
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20) !important;
    display: block;
    width: 440px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border: 1px solid #ECEDEF;
    padding-top: 20px;
    max-height: 69vh;
    overflow: auto;
}

.editReservation .headerElement {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    height: 48px;
    padding: 0 20px;
}
.editReservation .headerElement button {
    float: inline-end;
    color: #565C67;
}
.editReservation .headerElement button .anticon-close {
    font-size: 25px !important;
}
.editReservation .headerModal-title {
    font-size: 22px;
    color: #565C67;
}

.editReservation .bodyElement{
    padding: 24px 20px 0;
}
.editReservation  .examMethod{
    font-size: 17px;
    color: #565C67;
}
.editReservation .fieldInput {
    padding: 12px;
    color: #6C747B;
    background: #F8F8F8;
    border-radius: var(--radius-slightly-rounded, 6px);
    display: flex;
    flex-direction: column;
}
.editReservation .fieldInput .inputContent {
    display: flex;
    gap: 10px;
}
.editReservation .fieldInput .inputContent .fromto {
    padding-top: 6px;
}
.editReservation .fieldInput .inputContent label,
.editReservation .fieldCheckbox label
{
    flex: 1;
}
.editReservation .fieldInput .checkboxElement,
.editReservation .numberReservation span {
    font-size: 15px;
}
.editReservation .numberReservation .ant-input-number {
    width: 100%;
}

.editReservation .footerElement {
    margin-top: 30px;
    padding: 0 40px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: sticky;
    bottom: 0;
    background: #FFFFFF;
}
.editReservation .footerElement button {
    width: 100%;
    height: 60px;
    font-size: 16px;
    line-height: 16px;
}
.editReservation .button-setting,
.editReservation .button-setting:hover {
    color: #FFFFFF;
    background: #FF1493;
}
.editReservation .button-delete,
.editReservation .button-delete:hover {
    color: #6C747B;
    background: #F0F5F8;
}

.editReservation  .ant-col .ant-row .ant-col .ant-form-item {
    margin-bottom: 0px;
}

@media (max-width: 1366px)  { 
    .editReservation { 
        max-height: calc(69vh + 1.3em);
    }
}
.table-history .btn-reservation {
  border-color: #FFF2F9 !important;
  font-weight: 700 !important;
  color: #ff1493 !important;
  background-color: #FFF2F9 !important;
}
.table-history .btn-reservation:hover {
  border-color: #FFF2F9 !important;
  font-weight: 700 !important;
  color: #ff1493 !important;
  background-color: #FFF2F9 !important;
}
.table-history .ant-table-content > table {
  border-collapse: collapse;
}
.table-history table {
  min-width: 1000px;
}
.table-history table th.ant-table-cell {
  text-align: center;
}
@media (max-width: 768px) {
  .table-history {
    display: flex;
    width: 100%;
    margin: auto;
    flex-direction: column;
  }
  .table-history div.ant-table-content {
    overflow-x: scroll;
  }
}

@media (max-width: 1400px) {
  .table-history div.ant-table-content {
    overflow-x: scroll;
  }
}
.register-container>div>div {
    padding-bottom: 8px;
}

.register-container>div>div>span:nth-child(1) {
    padding-right: 8px;
}

.register-wrapper {
    width: 700px !important;
}

.register-container {
    /* padding: 8px; */
    margin: 32px 28px 0 0;
}

.register-container input {
    height: 58px;
}

.register-container .info-register {
    display: flex;
    height: 17px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.register-container .info-register span:nth-child(1) {
    color: #2E3138;
    font-size: 15px;
    line-height: 16px;
}

.register-container .info-domain {
    padding-bottom: 24px;
}

.register-container .info-domain span {
    color: #565C67;
    font-size: 12px;
    line-height: 16px;
}

.register-container .btn-radio {
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.register-container .ant-space {
    width: 100%;
}

.register-container .ant-picker {
    width: 100%;
    height: 58px;
}

.register-container .btn-step {
    margin-bottom: 16px;
}

.register-wrapper>.ant-modal-content>.ant-modal-header {
    border-bottom: 1px solid #D0D2D8;
    height: 60px;
    /* margin: 8px 12px 0 12px; */
    display: flex;
    align-items: center;
    margin-bottom: 0px !important;
}

.register-wrapper>.ant-modal-content>.ant-modal-header>.ant-modal-title {
    color: #6C747B;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
} 

.register-wrapper .ant-modal-close {
    margin: 16px 12px 0 0;
}

.register-wrapper .btn-step .buttonPt10-right>img {
    padding-right: 12px;
}
.register-wrapper .confirm {
    height: 56px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 56px;
}
.register-wrapper .confirm .error {
    color: #ff4d4f;
}
:where(.css-dev-only-do-not-override-3mqfnx).ant-input-number {
    width: 100% !important;
}
@media (max-width: 768px) {
    .register-container .col-pref {
        padding-right: 0 !important;
    }

    .register-container .col-city {
        padding-left: 0 !important;
    }
}
.register-wrapper .errcode {
    color: #ff4d4f;
    top: 0;
}
div.postal-code {
    margin-bottom: 5px;
}
div.postal-code>div {
    margin-bottom: 0px;
}
.err-color-code .ant-form-item-control-input-content>input {
    border-color: #ff4d4f;
}
* {
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  position: relative;
  width: 100%;
}

.wrapper-content {
  display: flex;
  padding: var(--gap-none, 0px) 40px 40px 40px;
  justify-content: center;
  min-height: calc(100vh - 165px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  font-family: sans-serif;
  width: 100%;
  min-height: 100vh;
} */

.App .navbar {
  width: 100%;
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  background: rgba(29, 161, 242, 1);
}

.App .navbar .nav-links {
  width: 40%;
  display: flex;
  justify-content: space-around;
  list-style: none;
}

.App .navbar .nav-links>a{
  color: #fff;
  text-decoration: none;
}

/* overwrite antd-css */
.ant-modal .ant-modal-close {
  z-index: 997 !important;
}

/* mobile */
@media (max-width: 768px) {
  .wrapper-content {
    padding: 0;
  }
}

/* tablet */
@media (min-width: 768px) and (max-width: 1200px)  { 
  .wrapper-content { 
    padding: var(--gap-none, 0px) 20px 40px 20px;
  }
}

/* mobile rotate */
@media (max-width: 1200px) and (max-height: 400px) {
  .wrapper-content {
    padding-bottom: 0 !important;
  }
}

::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ECEDEF;
  border-radius: 10px;
  width: 8px !important;
}

::-webkit-scrollbar-thumb {
  background: #D5D8DC;
  border-radius: 10px;
}
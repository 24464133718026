.infoHos .ant-table-wrapper {
    border: 1px solid #F2F3F5;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    padding: 16px 24px 40px 24px;
}
.infoHos .ant-table-cell:nth-child(1) span {
    color: #2E3138;
    font-size: 16px;
    line-height: 16px;
}
.infoHos .ant-table-cell:nth-child(2) span {
    color: #565C67;
    font-size: 16px;
    line-height: 16px;
}
.detail {
    align-items: center;
    color: #2e3138;
    padding: 24px 24px 8px 24px;
    background-color: #f8f8f8;
    border-radius: 6px;
    margin-bottom: 20px;
  }
.detail-box {
    display: grid;
    margin-bottom: 16px;
}
.header-detail{
    margin-bottom: 5px;
}
  
@media (max-width: 1600px) {
    /* overwrite antd-css */
    .infoHos .ant-table-row {
        display: flex;
        flex-direction: column;
    }
    .infoHos .ant-table-row .ant-table-cell:nth-child(1) {
        border-bottom: unset;
        padding-bottom: 0;
    }
    .infoHos .ant-table-row .ant-table-cell:nth-child(2) {
        justify-content: start !important;
        text-align: start !important;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    
}

@media (max-width: 1200px) {
    /* overwrite antd-css */
    .infoHos .ant-select-show-search {
        max-width: 100% !important;
    }
}


@media (max-width: 800px) {
    .infoHos {
        border-radius: 0;
    }
    /* overwrite antd-css */
    .infoHos .ant-table-row .ant-table-cell:nth-child(1) {
        width: unset;
    }
    .infoHos .ant-table-row .ant-table-cell:nth-child(2) {
        max-width: 70vw;
    }
    .infoHos .ant-table-wrapper .ant-table-tbody .ant-table-row >.ant-table-cell-row-hover {
        background-color: #ffffff;
    }
    .infoHos .ant-table-wrapper .ant-table-tbody >tr >td {
        border-bottom: none;
        padding: unset;
    }
    .infoHos .ant-table-wrapper .ant-table-tbody >tr {
        border-bottom: 1px solid #f0f0f0;
        padding: 12px 8px 8px 8px;
    }
    .infoHos .ant-table-wrapper .ant-table-tbody >tr .infoHos-value {
        margin-top: 8px;
    }
}
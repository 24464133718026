.cardInfo .btnEdit{
    color: #308AE6;
    font-size: 16px;
    background: transparent;
    border: none;
}
.cardInfo .btnEdit:hover {
    color: #308AE6 !important;
    font-size: 16px;
    background: transparent !important;
    border: none;
}
.cardInfo .ant-modal-title {
    font-weight: 700;
    font-size: 17px;
    color: #565C67;
    border-bottom: solid 1px #D0D2D8;
    padding: 17px 0;
    margin-top: 7px;
}

.cardInfo table tr td:nth-child(2) {
    text-align: right;
    color: #565C67;
    font-weight: 700;
}
.cardInfo .ant-modal-header {
    border-bottom: none !important;
}
.cardInfo table {
    width: 100%;
    margin-bottom: 20px;
}

.cardInfo td {
    height: 56px !important;
    border-bottom: 1px solid #F2F3F5;
    padding-bottom: 0;
    vertical-align: bottom;
}

.cardInfo .ant-modal-content{
    width: 800px;
    left: calc(50% - 400px);
    padding: 24px 40px 48px 40px;
    border-radius: 0 !important;
}
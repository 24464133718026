.footer {
    height: 82px;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #F2F3F5;
    background: #FFF;
}
.footerLogo {
    margin-right: 48px;
}
.footerText {
    color: #2E3138;
    font-size: 16px;
    line-height: normal;
}

.footer-mobile {
    /* height: 0px; */
}

/* mobile */
@media (max-width: 768px) {
    .footerLogo {
        margin-right: 30px;
    }
    .footer-mobile {
        height: 160px !important;
    }
}

@media (max-width: 1200px) {
    .footer-mobile {
        height: 100px;
    }
}
.dt011 .thick {
  font-weight: bold;
  color: #565c67;
  font-size: 17px;
  padding: 20px 0 20px 0;
}

.dt011 .title {
  color: #6c747b;
  font-weight: bold;
  font-size: 22px;
}

.dt011 {
  border-top: 1px solid #ecedef;
  padding: 20px;
  max-height: 63vh;
  overflow: auto;
}
.dt011 table {
  border-collapse: collapse;
  margin-bottom: 15px;
}

.dt011 .table th {
  color: #565c67 !important;
  text-align: center !important;
  word-break: keep-all;
  background: #FFFFFF !important;
}

.dt011 .table th,
.dt011 .table td {
  border: 1px solid #ecedef !important;
  padding: 10px 6px !important;
}

.padding20 {
  padding-bottom: 20px;
}

.clinic_user {
  background: #f0f5f8;
  color: #6c747b;
  margin: 0 10px 10px 10px;
  border-radius: 50px;
  padding: 10px;
  text-align: center;
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
  font-weight: 400;
}

.setting-btn {
  color: #308ae6;
  border: 2px solid #b2d6f6;
}

.closeBtn {
  border: none;
  float: right;
  box-shadow: none;
  height: fit-content;
  padding: 0 !important;
  margin: 7px;
}

.dt011 td {
  font-weight: normal;
  word-break: break-all;
}

.dt011 .table:nth-child(2) > tbody > tr > td:first-child {
  color: #308ae6;
}

.rsvId {
  color: #308ae6;
  width: fit-content;
}

.dt011 .dateTime {
  font-weight: normal;
}

.setting-btn {
  font-weight: 700;
  height: 36px;
  width: 130px;
}
.setting-btn img {
  margin-right: 10px;
}

.dt011 *:focus {
  outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}

.dt011 table thead .ant-table-cell::before{
  display: none !important;
}

.dt011
.ant-table-container
table
> thead
> tr:first-child
> *:last-child {
  border-radius: 0 !important;
  background: #ffffff;
}

.dt011
.ant-table-container
table
> thead
> tr:first-child
> *:first-child {
  border-radius: 0 !important;
  background: #ffffff;
}

.dt011::-webkit-scrollbar {
  width: 6px;
}

.dt011::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ECEDEF;
  border-radius: 10px;
  width: 8px !important;
}

.dt011::-webkit-scrollbar-thumb {
  background: #D5D8DC;
  border-radius: 10px;
}

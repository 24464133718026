/* overwrite antd-css */
.ant-modal-wrap .modalDeleteOrder {
    width: 640px !important;
}
.modalDeleteOrder .title {
    text-align: center;
    color: #000000 !important;
    font-weight: normal !important;
    font-size: 14px !important;
}
.modalDeleteOrder .orderList {
    border-radius: var(--radius-slightly-rounded, 6px);
    background: var(--background-footer, #F8F8F8);
    padding: 24px;

    display: flex;
    flex-direction: column;
    gap: 16px;
}
.modalDeleteOrder .orderList .itemTitle {
    font-size: 17px;
    line-height: 32px;
    margin-bottom: 4px;
}
.modalDeleteOrder .notice img {
    margin-right: 6px;
}
.modalDeleteOrder>.ant-modal-content>.ant-modal-header {
    border-bottom: 1px solid #D0D2D8;
    height: 60px;
    margin-top: 8px;
    display: flex;
    align-items: center;
}
.modalDeleteOrder>.ant-modal-content>.ant-modal-header>.ant-modal-title {
    color: #6C747B;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}
.modalDeleteOrder>.ant-modal-content>.ant-modal-close {
    margin: 16px 8px 0 0;
}
.modalDeleteOrder>.ant-modal-content>.ant-modal-body>.ant-row>.bodyModal {
    margin-top: 32px;
}

/* mobile-css */
@media (max-width: 768px) {
    /* overwrite antd-css */
    .ant-modal-wrap .modalDeleteOrder {
        width: 400px !important;
    }
    .modalDeleteOrder .notice {
        text-align: center;
    }
}
  
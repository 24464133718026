.dt012 .hidden {
  display: none;
}

.dt012 {
  padding: 20px;
  border-top: 1px solid #ecedef;
  max-height: 63vh;
  overflow: auto;
}

.frameList .dt012 {
  width: 480px;
}

.dt012 tr td:first-child {
  font-weight: 400;
  font-size: 15px;
}

.dt012 tr td:last-child {
  font-weight: normal;
  text-align: right;
  justify-content: flex-end;
  color: #565C67;
  font-size: 14px;
}

.dt012 tr td {
  padding: 16px; 
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: #F2F3F5 1px solid;
  vertical-align: bottom;
  box-sizing: content-box;
  white-space: nowrap;
}

.dt012 .wrap-table {
  /*min-height: 560px;*/
  /*min-width: 440px;*/
}

.dt012 .title {
  font-size: 22px;
  color: #565c67;
}

.wrap-table {
  padding: 16px 24px 40px 24px;
  border: 1px solid #ecedef;
  box-shadow: 0 2px 4px 0 #2e313833;
  margin-top: 30px;
}
.dt012-tabs {
  box-shadow: 0 2px 4px 1px #2e313833 !important;
  padding-top: 0 !important;
  margin-top: 30px;
  padding-bottom: 40px;
}

.dt012-tabs .ant-tabs-tab {
  width: 100px !important;
  min-width: 100px !important;
  flex: auto;
}

.dt012-tabs .ant-tabs-nav-wrap{
  max-width: 100% !important;
}
.dt012-tabs .ant-tabs-nav-list {
  display: flex;
  margin-right: 0 !important;
  width: 100vh;
  margin-bottom: unset !important;
}

.dt012-tabs .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #308AE6 !important;
}


.dt012-tabs .ant-tabs-tabpane{
  padding: 16px 10px 0 10px;
}

.dt012-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab:first-child {
  border-bottom: none;
  border-radius: 0 !important;
}
.dt012-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab:nth-child(2) {
  border-bottom: none;
  border-radius: 0 !important;
}

.dt012-tabs .textArea {
  width: 100%;
  border: 1px solid #d0d2d8;
  border-radius: 6px;
  min-height: 140px;
  padding: 12px;
}

.dt012-tabs .submitBtn {
  color: #ffffff;
  background: #ff1493;
  width: 139px;
  height: 36px;
}

.consentFormModal .ant-modal-content {
  padding: 20px !important;
}

.consentFormModal .ant-modal-header {
  text-align: center;
}

.consentFormModal .ant-modal-body {
  padding: 10px;
  background: #f8f8f8;
  border-radius: 6px;
}

.consentFormModal button {
  background: #f0f5f8;
  color: #6c747b;
}

.consentFormModal .ant-modal-footer {
  text-align: center;
}

.cancelBtn {
  width: 80%;
  background: #f0f5f8;
  font-weight: 700;
  color: #6c747b;
  margin: 20px 0 20px 0;
  height: 60px;
  border: none;
  font-size: 16px;
  border-radius: 6px;
}

.dt012-tabs .messErr {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 16px;
}

.modalCancel .ant-modal-content{
  color: #2E3138 !important;
}
.modalCancel .ant-radio-group{
  margin-bottom: 50px;
}

.modalCancel .ant-radio-wrapper {
  display: block;
  line-height: 30px !important;
}

.modalCancel textarea {
  height: 135px !important;
  width: 100% !important;
  border-radius: 6px;
  border: 1px #D0D2D8 solid;
  padding: 12px;
  outline: none;

}

.modalCancel textarea ::placeholder {
  color: #BABDC5;
}

.modalCancel .ant-modal-header {
  height: 50px;
  border-bottom: #D0D2D8 1px solid;
  vertical-align: center;
  display: flex;
  padding: 18px 0 18px 0;
}

.modalCancel .ant-modal-title {
  color: #6C747B !important;
}

.modalCancel .ant-btn{
  width: 100%;
  height: 60px;
  background: #F0F5F8;
  color: #6C747B;
  font-weight: 700;
}

.modalCancel {
  width: 600px;
  height: 572px;
  margin: 0 32px 40px 32px;
}

.modalConfirmCancel  .ant-modal-content{
  padding: 0 40px 40px 40px !important;
}
.modalConfirmCancel  .ant-btn:nth-child(2){
  background: #F0F5F8;
  color: #6C747B;
  width: 50%;
}
.modalConfirmCancel  .ant-btn:nth-child(2):hover{
  background: #F0F5F8;
  color: #6C747B;
}
.modalConfirmCancel  .ant-btn:first-child{
  background: #FF1493;
  color: #FFFFFF;
  width: 50%;
}
.modalConfirmCancel  .ant-btn:first-child:hover{
  background: #FF1493;
  border: solid #308AE6 1px;
}

.modalConfirmCancel .ant-modal-header {
  height: 50px;
  border-bottom: #D0D2D8 1px solid;
  vertical-align: center;
  display: flex;
  padding: 18px 0 18px 0;
}

.modalConfirmCancel .ant-modal-title {
  color: #6C747B !important;
}

.dt012 *:focus {
  outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}

.modalConfirmCancel *:focus{
  outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}
.modalConfirmCancel input[type="radio" i]:focus-visible {
  outline: none !important;
  border: 2px solid red !important;
  border-radius: 6px;
}

.modalCancel *:focus{
  outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}

.dt012::-webkit-scrollbar {
  width: 6px;
}

.dt012::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ECEDEF;
  border-radius: 10px;
  width: 8px !important;
}

.dt012::-webkit-scrollbar-thumb {
  background: #D5D8DC;
  border-radius: 10px;
}

.dt012 .docsBtn {
  width: fit-content;
  height: fit-content;
  background: transparent;
  border: none;
  padding: 0;
}
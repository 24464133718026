.prescriptionReceiveConfirm {
    padding: 24px 40px 48px 40px;
}

.prescriptionReceiveConfirm .itemTitle,
.prescriptionReceiveConfirm .prescription-title
{
    color: #565C67;
    font-size: 17px;
}

.prescriptionReceiveConfirm .itemTitle {
    line-height: 32px;
}
.prescriptionReceiveConfirm .itemContent span {
    line-height: 1.5;
}

.prescriptionReceiveConfirm .prescription-method {
    font-size: 15px;
}
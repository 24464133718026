.tabs_register_address_container {
    padding: 40px;
    margin-bottom: 20px;
    box-shadow:
      0px 8px 15px 2px rgba(46, 49, 56, 0.1),
      0px 0px 2px 0px rgba(46, 49, 56, 0.2);
}
.tabs_register_address_container_modal {
    padding: unset;
    margin: unset;
    box-shadow: unset;
}

.tabs_register_address_container>.ant-row:nth-child(1)>.ant-col>span {
    color: #565C67;
    font-size: 17px;
    font-weight: 700;
    line-height: normal;
}
.tabs_register_address_container>.ant-row:nth-child(2)>.ant-col>span {
    color: #2E3138;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
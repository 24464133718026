.registerCard .ant-select {
    width: 45%;
    height: 58px;
}
.registerCard .ant-modal-header {
    border-bottom: none !important;
}
.registerCard .ant-select:nth-child(2) {
    margin-right: 10px;
}
.registerCard .ant-modal-content{
    width: 800px;
    height: 670px;
    padding: 0 40px !important;
    left: calc(50% - 400px);
    border-radius: 0 !important;
}
.registerCard .confirmBtn {
    background: #FF1493;
    width: 300px;
    color: #FFFFFF;
    height: 60px;
    font-weight: 700;
    font-size: 16px;
}

.registerCard .confirmBtn:hover {
    background: #FF1493 !important;
    color: #FFFFFF !important;
}

.registerCard .ant-modal-title {
    font-weight: 700;
    font-size: 17px;
    color: #565C67;
    border-bottom: solid 1px #D0D2D8;
    padding: 17px 0;
    margin-top: 7px;
}

.registerCard .cardList img{
    width: 70px;
    height: 48px;
    margin-right: 8px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.registerCard input {
    height: 58px;
}

.registerCard table{
    font-size: 15px;
}

.registerCard table tr:nth-child(3) td{
    height: 50px;
    vertical-align: bottom;
}

.registerCard .info1 {
    font-size: 14px;
    font-weight: 700;
    color: #2E3138;
}

.registerCard .ant-select-selection-placeholder{
    color: #2E3138;
}

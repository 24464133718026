.booking-header {
    position: relative;
}

.container-calendar {
    background: initial;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.text-2 {
    color: #565C67;
    font-size: 16px;
    line-height: normal;
    margin: 16px 0 24px 0;
}

.content-calendar {
    display: flex;
    padding: 0px;
    align-items: flex-start;
    gap: 80px;
    align-self: stretch;
}

.container-time {
    flex-grow: 1;
    display: flex;
    padding: var(--gap-none, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
}
.userLoggedIn {
    display: flex;
    align-items: center;
}
.userLoggedIn span {
    color: #565C67;
    font-size: 14px;
    line-height: 16px;
}

.userLoggedIn .logout {
    cursor: pointer;
}

.userLoggedIn .userName {
    max-width: 130px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
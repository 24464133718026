/* overwrite antd-css */
.ant-modal-wrap .modalDoctorConfirmPreOrder {
    width: 1600px !important;
}
.modalDoctorConfirmPreOrder .ant-modal-body {
    max-height: unset;
}
.tabbarFilter {
    align-items: center;
}
.tabbarFilter .searchRadio {
    display: flex;
    gap: 32px;
}
.buttonDt04_05 {
    background-color: #f0f5f8;
    color: #6C747B;
    height: 60px;
    cursor: pointer;
    width: 300px;
}

/* overwrite antd-css */
.tabbarFilter .ant-form-item {
    margin-bottom: 0;
}

/* mobile-css */
@media (max-width: 768px) {
    /* overwrite antd-css */
    .ant-modal-wrap .modalDoctorConfirmPreOrder {
        width: 400px !important;
        height: auto !important;
    }
}
@media (max-width: 1366px)  { 
    .tabbarFilter .fieldSearchDate { 
        padding-bottom: 20px;
    }
}
/* overwrite antd-css */
.ant-modal-wrap .modalDetailPharmacy {
  width: 640px !important;
}
.modalDetailPharmacy .detailPharmacy {
  border-radius: 6px;
  background: #F8F8F8;
  padding: 24px;
}
.detailPharmacy-left,
.detailPharmacy-right
{
  display: flex;
  gap: 16px;
  flex-direction: column;
}
.modalDetailPharmacy .detailPharmacy .itemTitle {
  font-size: 17px;
  line-height: 32px;
  margin-bottom: 4px;
}
.modalDetailPharmacy .detailPharmacy .itemContent {
  line-height: 16px;
}
.detail_pharmacy {
  border-radius: 6px;
  background: #F8F8F8;
  margin: 36px 0;
  padding: 24px;
}
.qrcode_container {
  display: flex;
  background: #FFFFFF;
}
.qrcode_container>.qrcode {
  margin: 10px;
  padding: 2px;
  width: 104px;
  height: 104px;
  background-color: #565C67;
  display: flex;
}
.qrcode_container>div:nth-child(2) {
  display: flex;
  margin-top: 8px;
}
.qrcode_container>div>span {
  color: #565C67 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}
.detail_pharmacy>.ant-row>.ant-col>.detailPharmacy>.ant-col>div>div>span {
  color: #565C67;
  font-size: 17px;
  font-weight: 700;
  line-height: normal;
}
.detailPharmacy-right>.link_line>span {
  color: #2E3138;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.line_container {
  border-radius: 36px;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(46, 49, 56, 0.10), 0px 2px 2px 0px rgba(46, 49, 56, 0.10);
  padding: 16px;
  display: none;
}
.line_container>.line_button {
  border-radius: 60px;
  background: #06AD53;
  height: 60px;
  width: 100%;
  margin-bottom: 8px;
}
.line_container>.line_button:hover {
  background: #06AD53 !important;
}
.line_container>.line_button>a>img {
  padding-right: 16px;
}
.line_container>.line_button>a>span {
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}
.line_container>div {
  padding: 10px 8px;
}
.line_container>div>div>span {
  color: #565C67;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}
.line_container>div>.add_friend {
  padding-bottom: 8px;
}
.regis_address_other>.detail_pharmacy .itemContent>span {
  color: #565C67 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}
/* mobile-css */
@media (max-width: 768px) {
  /* overwrite antd-css */
  .ant-modal-wrap .modalDetailPharmacy {
      width: 400px !important;
  }
  .line_container {
    display: block !important;
  }
}

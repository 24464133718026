.editCard .ant-select {
    width: 45%;
    height: 58px;
}

.editCard .ant-modal-header {
    border-bottom: none !important;
}

.editCard .ant-select:nth-child(2) {
    margin-right: 10px;
}

.editCard .ant-modal-content {
    width: 800px;
    left: calc(50% - 400px);
    border-radius: 0 !important;
}

.editCard .confirmBtn {
    background: #FF1493;
    width: 300px;
    color: #FFFFFF;
    height: 60px;
    font-weight: 700;
    font-size: 16px;
}

.editCard .confirmBtn:hover {
    background: #FF1493 !important;
    color: #FFFFFF !important;
}

.editCard .ant-modal-title {
    font-weight: 700;
    font-size: 17px;
    color: #565C67;
    border-bottom: solid 1px #D0D2D8;
    padding: 17px 0;
    margin-top: 7px;
}

.editCard .cardList img {
    width: 70px;
    height: 48px;
    margin-right: 8px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.editCard input {
    height: 58px;
}

.editCard table {
    font-size: 15px;
}

.editCard .formTable tr:nth-child(3) td {
    height: 50px;
    vertical-align: bottom;
}

.editCard .info1 {
    font-size: 14px;
    font-weight: 700;
    color: #2E3138;
}

.editCard .ant-select-selection-placeholder {
    color: #2E3138;
}

.editCard .infoTable {
    width: 100%;
    margin-bottom: 64px;
}

.editCard .infoTable td {
    height: 56px !important;
    border-bottom: 1px solid #F2F3F5;
    padding-bottom: 0;
    vertical-align: bottom;
}

.editCard .infoTable tr td:nth-child(2) {
    text-align: right;
    color: #565C67;
    font-weight: 700;
}
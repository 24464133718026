.search-postcode .postal-code {
    display: flex;
    flex-direction: column;
}
.search-postcode .info-register {
    margin-bottom: 8px;
}

.search-postcode .info-register>span:nth-child(1) {
    padding-right: 8px;
}

.search-postcode input {
    height: 58px;
}
.search-postcode .ant-select {
    height: 36px;
}

.search-postcode .col-pref {
    padding-right: 10px;
}
.search-postcode .col-city {
    padding-left: 10px;
}

.search-postcode .col-search {
    height: 58px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.search-postcode .btn-search {
    width: 100%;
    height: 44px;
    border-radius: 6px;
    border: 1px solid #FFF2F9;
    background: #FFF2F9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prescription .search-postcode>div>.ant-row>.ant-col>.ant-form-item>.ant-row>.ant-col>div:nth-child(2) {
    white-space: nowrap;
}

.search-postcode .btn-search img {
    padding-right: 12px;
}

.search-postcode .btn-search span {
    color: #FF1493;
    font-size: 16px;
    line-height: 16px;
}

/* mobile */
@media (max-width: 768px) {
    .search-postcode .col-pref {
        padding-right: unset;
    }
    .search-postcode .col-city {
        padding-left: unset;
    }
    .search-postcode .postal-code-row {
        justify-content: center;
    }
}
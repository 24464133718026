.download_print {
    position: fixed;
    background: #f2f3f5;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}
.download_print>div {
    display: flex;
    align-items: center;
}
.download_print>div>div:nth-child(1) {
    margin-left: 10px;
}
.download_print>div>div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}
.download_print>div>div:nth-child(2)>button {
    margin: 10px 10px 10px 0;
}
.download_csv>a>span {
    margin-right: 5px;
}
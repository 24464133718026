@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  background-color: white;
}

@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px
  }
}

@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px
  }
}

@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px
  }
}

@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px
  }
}

@media (min-width: 476px) {
  .container {
    width: 100%
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%
  }
}

/* Apply fonts for application */
@font-face {
  font-family: "Meiryo";
  src: url("src/fonts/Meiryo.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Meiryo";
  src: url("src/fonts/Meiryo-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Meiryo";
  src: url("src/fonts/Meiryo-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Meiryo";
  src: url("src/fonts/Meiryo-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

*, input {
  font-style: normal;
  font-family: 'Meiryo' !important;
}
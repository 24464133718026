.dt047 {
    width: 440px;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20) !important;
    padding: 20px;
    border: 1px solid #ECEDEF;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dt047 .updateBtn {
    width: 45%;
    background: #FF1493;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
    height: 60px;
}

.dt047 .deleteBtn {
    width: 45%;
    background: #F0F5F8;
    font-weight: 700;
    font-size: 16px;
    color: #6C747B;
    border: none;
    height: 60px;
}

.dt047 .title{
    justify-content: space-between;
    display: flex;
}

.dt047 .selectTime {
    width: 200px;
}

.dt047 .selectTime .ant-select-selection-item {
    background-image: url('src/assets/image/clock.svg');
    background-repeat: no-repeat;
    /*padding-left: 30px !important;*/
    background-position: 85% 6px;
}

.dt047 .treatmentMenu {
    height: 32px;
    margin: 10px 0 10px 0;
    font-size: 17px;
    font-weight: 700;
    color: #565C67;
}

.modalConfirmDelete{
    padding: 10px;
}

.modalConfirmDelete .ant-modal-content {
    width: 600px;
    height: 437px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
}

.modalConfirmDelete .ant-btn:nth-child(1){
  background: #FF1493 !important;
  font-weight: 700;
    width: 50%;
    height: 100%;
}
.modalConfirmDelete .ant-btn:nth-child(2){
  background: #F0F5F8 !important;
  color: #6C747B;
  font-weight: 700;
    width: 50%;
    height: 100%;

}
.modalConfirmDelete .ant-btn:nth-child(1):hover{
  background: #FF1493 !important;
  border: solid #308AE6 1px !important;
}

.modalConfirmDelete .ant-modal-header .ant-modal-title {
    color: #6C747B;
    font-size: 18px;
}

.modalConfirmDelete .ant-modal-header {
    display: flex;
    justify-content: start;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px #ECEDEF solid;
    padding: 18px 0;
}

.modalConfirmDelete .ant-modal-footer{
    display: flex;
    height: 60px;
    font-size: 16px;
}


.dt047 *:focus{
  outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}

.modalConfirmDelete     *:focus{
    outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}
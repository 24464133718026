.prescription .prescription-info-row input {
    height: 58px;
}
.prescription .prescription-info-row {
    margin: 10px 0 0 0;
    width: 100%;
}
.prescription .prescription-info-row-first {
    width: 160% !important;
}
.prescription .prescription-info-row .prescription-info-input {
    margin-top: 8px;
}
.prescription .prescription-info-row .ant-tag {
    bottom: 2px;
    left: 3px;
}
.row-modal .prescription-info-row {
    display: flex;
    align-items: center;
    width: auto;
    padding-top: 10px;
    grid-gap: 20px;
}
.prescription .prescription-info-row>.pharmacy_name {
    padding-right: 10px;
}
.prescription .prescription-info-row>.fax {
    padding-left: 10px;
}
.prescription .prescription-info-button .back {
    border-radius: 6px;
    background: #8B969E;
    width: 100%;
    height: 60px;
    padding-right: 10px;
}
.prescription .prescription-info-button .back>img {
    padding-right: 12px;
}
.prescription .prescription-info-button .register {
    padding-left: 10px;
}
.prescription .prescription-info-button .back>span, .prescription .prescription-info-button .register>input {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
}
.prescription {
    padding: 24px;
    border-radius: 6px;
    background: #F2F3F5;
}
.prescription .prescription-info-button>.ant-col>.back:hover {
    color: #FFFFFF;
    background: #8B969E;
}

.prescription .search-postcode  .ant-select-in-form-item  {
    height: 58px !important;
}

@media (max-width: 768px) {
    
    .prescription-info-button {
        flex-direction: column-reverse;
    }

    .search-postcode .col-pref {
        padding-right: 0 !important;
    }
    .search-postcode .col-city {
        padding-left: 0 !important;
    }
    .prescription .prescription-info-row>.pharmacy_name {
        padding-right: 0 !important;
    }
    .prescription .prescription-info-row>.fax {
        padding-left: 0 !important;
    }
    .prescription .prescription-info-button .back {
        padding-right: 0 !important;
        margin-bottom: 16px;
    }
    .prescription .prescription-info-button .register {
        padding-left: 0 !important;
    }
    .tabs_register_address_container {
        padding: 16px !important;
    }
    .tabs_register_address_list>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab:nth-child(1) {
        width: 45% !important;
    }
    .tabs_register_address_list>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab:nth-child(2) {
        width: 30% !important;
    }
    .tabs_register_address_list>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab:nth-child(3) {
        width: 25% !important;
    }
}
.tableReservationSettingHSB .ant-table {
    max-width: calc(100vw - (440px + 1.2em));
}

.tableReservationSetting .treatMentMenuInfo {
    cursor: pointer !important;
}
/* custom scrollbar of browser (chrome, safari) */
.tableReservationSetting .ant-table ::-webkit-scrollbar {
    width: 1.1em;
    height: 1.1em;
}
.tableReservationSetting .ant-table ::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
.tableReservationSetting .ant-table ::-webkit-scrollbar-corner,
.tableReservationSetting .ant-table ::-webkit-scrollbar-thumb {
    background: #c4c4c4; 
}
.tableReservationSetting .ant-table ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8; 
}

.tableReservationSetting .hiddenText {
    font-size: 0 !important;
}

.tableReservationSetting .hiddenElement {
    visibility: hidden !important;
}

.tableReservationSetting .ant-table-tbody > tr > .cellTreatMentMenuOrdered {
    text-align: left !important;
    vertical-align: top;
    border-left: 4px solid var(--color-primary-500, #FF1493);
    background: var(--color-primary-20, #FFF9FC);
    cursor: pointer;
}
.tableReservationSetting .ant-table-tbody > tr > .cellTreatMentMenuOrdered:hover {
    background: var(--color-primary-20, #FFF9FC);
}
.tableReservationSetting .ant-table-tbody > tr > .cellTreatMentMenuOrdered .cellTreatMentMenuOrderedOver {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    cursor: pointer;
}

.tableReservationSetting .newTreatMentMenu {
    padding: 8px 16px;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #FF1493;
    background: #FFF2F9;
}
.tableReservationSetting .newTreatMentMenu:hover {
    color: #FF1493 !important;
}

.tableReservationSetting .grayBox
{
    background-color: #F2F3F5;
}

.tableReservationSetting .grayTextTitle {
    color: #6C747B;
}

.tableReservationSetting .grayText {
    color: #565C67;
}

.tableReservationSetting .titleColum {
    color: #565C67;
    display: flex;
    flex: 1;
    justify-content: center;
}

.tableReservationSetting {
    flex: 1;
}

.tableReservationSetting .disablePastDay {
    cursor: not-allowed;
}
.tableReservationSetting .buttonDisablePastDay {
    background: #F2F3F5;
    cursor: not-allowed;
}
/* overwrite antd-css */
.tableReservationSetting .ant-table-thead > tr > th,
.tableReservationSetting .ant-table-tbody > tr > td {
    text-align: center !important;
}

.tableReservationSetting .ant-table-thead >tr>th,
.tableReservationSetting .ant-table-thead >tr>td {
    background: #fff !important;
    border: 1px solid #f0f0f0;
    border-collapse: collapse;
    padding: 12px !important;
}

.displayClosedTime {
    position: absolute;
    width: 100%;
    background: rgba(128,128,128,0.5);
    top: 0;
    left: 0;
    z-index: 2;
    color: white;
    font-size: 14px;
    text-align: center;
}

.displayClosedTime:focus {
  outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}

.displayRsvRepeatFrame {
    position: absolute;
    width: 100%;
    background: #FFF9FC;
    top: 0;
    left: 0;
    z-index: 1;
    color: #6C747B;
    font-size: 12px;
    text-align: left !important;
    border-left: 5px #FF1493 solid;
    border-top: 1px #FF1493 solid;
    border-bottom: 1px #FF1493 solid;
    border-radius: 7px;
    border-right:  1px #FF1493 solid;
    overflow-y: auto;
    padding: 12px 20px;
    cursor: pointer;
}

.displayRsvRepeatFrame .exmMethod {
    background: #fff;
    border-radius: 60px;
    box-shadow: 0px 1px 2px 0px rgba(46, 49, 56, 0.10);
    padding: 2px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.displayRsvRepeatFrame .exmMethod .label {
    border-radius: 60px;
    background: #FF1493;
    color: #fff;
    padding: 2px 8px;
}
.displayRsvRepeatFrame .exmMethod .labelOffline {
    background: #FF1493;
}
.displayRsvRepeatFrame .exmMethod .labelOnline {
    background: #308AE6;
}

.tableReservationSetting table thead .ant-table-cell::before{
    display: none !important;
}
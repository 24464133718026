.buttonPt04-cancel {
    background-color: #F0F5F8;
    color: #6C747B;
    width: 100%;
    height: 60px;
    font-size: 16px;
}
/* overwrite antd-css */
.ant-modal-wrap .modalPt04_1 {
    width: 640px !important;
}
.bodyModal-input {
    height: 60px;
}
/* mobile-css */
@media (max-width: 768px) {
    /* overwrite antd-css */
    .ant-modal-wrap .modalPt04_1 {
        width: 400px !important;
    }
}

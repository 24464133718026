.search_pharmacy>.prescription-info-label>span {
    color: #2E3138;
    font-size: 15px;
    line-height: 16px;
}
.search_pharmacy .prescription-info-label input {
    height: 50px;
}
.search_pharmacy .relativeRow {
    position: relative;
}
.search_pharmacy .search_pharmacy_regis {
    background: #FFF;
    border-radius: 4px;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    min-width: calc(300% - 24px);
    padding: 30px;
    position: absolute;
    left: 0;
    z-index: 10;
}
.search_pharmacy .result_pref {
    left: 12px;
}
.search_pharmacy .result_city {
    left: calc(-100% + 12px);
}
.search_pharmacy .result_pharmacy {
    left: -195%;
}
.search_pharmacy .search_pharmacy_regis .modal-keyword-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 170px;
    overflow-x: auto;
}
.search_pharmacy .search_pharmacy_regis .modal-keyword-item {
    background: #edf4fc;
    border: 1px solid #1e6097;
    border-radius: 2em;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    margin: 0.75em 0.75em 0 0;
    line-height: 1;
    padding: 0.4em 1.25em;
    white-space: nowrap;
    transition: 0.5s all;
}
@media (max-width: 768px) {
    .search_pharmacy .search_pharmacy_regis {
        min-width: calc(100% - 24px);
    }
}
@media (max-width: 1200px) {
    .search_pharmacy .search_pharmacy_regis {
        min-width: calc(100% - 24px);
    }
    .search_pharmacy .result_city,
    .search_pharmacy .result_pharmacy {
        left: 10px;
    }
}
.tab-regist-medical {
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20) !important;
    display: block;
    width: 440px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border: 1px solid #ECEDEF;
    max-height: 63vh;
    overflow: auto;
}

.tab-regist-medical>div {
    margin: 20px;
}


.tab-regist-medical .button-regist-medical,
.tab-regist-medical .button-regist-medical:hover {
    width: 100%;
    height: 60px;
    border-radius: 6px;
    background: #FF1493;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: #FF1493 !important; */
    border: unset !important;
    font-size: 16px;
    line-height: 16px;
    margin-top: 20px;
}

.tab-regist-medical .button-regist-medical-container {
    height: 100px;
    min-width: 92%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: sticky;
    bottom: 0;
    background: #FFFFFF;
    margin-left: 4%;
    margin-right: 4%;
    padding: 20px;
}

.tab-regist-medical>.ant-col {
    margin-top: 0;
}
.tab-regist-medical .button-disabled,
.tab-regist-medical .button-disabled:hover {
    border-radius: 6px;
    background: #FFC7E3;
}

.button-regist-medical span {
    color: #FFFFFF;
    font-size: 16px;
    line-height: 16px;
}

.headerModal-regist-medical {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    gap: 10px;
    align-self: stretch;
    height: 60px;
}

.headerModal-regist-medical button {
    float: inline-end;
    color: #565C67;
}

.headerModal-regist-medical button .anticon-close {
    font-size: 25px !important;
}

.headerModal-regist-medical .headerModal-title {
    font-size: 22px;
    line-height: normal !important;
}
.tab1-element>div>label {
    line-height: normal !important;
    color: #565C67;
}
.tab1-element>div>.text-normal-styles {
    color: #000000 !important;
}
.inline-confirm-box {
    display: flex;
    overflow-wrap: anywhere;
}
.prescription-title2 {
    font-weight: bold;
    color: #565c67;
    font-size: 17px;
}
/* .tab-tab-regist-medical .ant-tabs-card {
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
} */

.tab-tab-regist-medical {
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20) !important;
    padding-top: 0 !important;
    border-radius: 10px 10px 0 0 !important;
}

.tab-tab-regist-medical .ant-tabs-tab {
    background-color: #f2f3f5 !important;
    min-width: 50% !important;
    width: 50% !important;
    height: 59px;
    font-size: 14px;
    font-family: 'Meiryo';
    justify-content: center;
    line-height: 21px;
}

.tab-regist-medical .ant-tabs-nav-wrap .ant-tabs-nav-list {
    margin-right: 0px !important;
}
.tab-regist-medical .ant-tabs >.ant-tabs-nav .ant-tabs-nav-wrap {
    flex: auto !important;
    display: unset !important;
}

.text-area-regist-medical-tab1 .ant-input-textarea-show-count .ant-input-data-count {
    left: 0px;
}

.tab1-element .ant-radio-group .ant-radio-wrapper  {
    padding-right: 20px;
}

.tab1-element .ant-tag {
    margin-left: 5px;
}

.tab-regist-medical .ant-tabs-content-holder {
    margin: 20px;
}

.tab-regist-medical .ant-tabs-tab-active {
    background: #ffffff;
}

.buttonWarningClose {
    background-color: #f0f5f8;
    color: #6C747B;
    width: 240px;
    height: 60px;
}

.buttonWarningAccept {
    background-color: #FF1493;
    color: #FFFFFF;
    width: 240px;
    height: 60px;
}

.modalConfirm  .ant-modal-content{
    padding: 40px !important;
  }
  .modalConfirm  .ant-btn:first-child{
    order: 1;
    justify-content: flex-end;
    background-color: #f0f5f8;
    color: #6C747B;
    width: 240px;
    height: 60px;
  }
  .modalConfirm  .ant-btn:first-child:hover{
    order: 1;
    justify-content: flex-end;
    background-color: #f0f5f8;
    color: #6C747B;
    width: 240px;
    height: 60px;
  }
.modalConfirm  .ant-btn:nth-child(2){
    margin-right: 15px;
    justify-content: flex-start;
    background-color: #FF1493;
    color: #FFFFFF;
    width: 240px;
    height: 60px;
  }

  .modalConfirm  .ant-btn:nth-child(2):hover{
    margin-right: 15px;
    justify-content: flex-start;
    background-color: #FF1493;
    color: #FFFFFF;
    width: 240px;
    height: 60px;
  }

  .modalConfirm .ant-modal-footer {
    display: flex;
  }

  .text-area-regist-medical-tab1 .ant-form-item-explain-error {
    padding-top: 20px;
  }

.scheduleTabs .tab-regist-medical .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    width: 50% !important;
}
.booking-avatar {
    margin-top: -50%;
    margin-left: -4px;
}
.booking-avatar img {
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 300px;
    border-radius: 0px 40px 40px 40px;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
}

@media (max-width: 768px) {
    .booking-avatar {
        margin-top: 0;
        margin-left: 0;
        height: 210px;
    }
    .booking-avatar img {
        height: 200px;
        width: 200px;
        background: #FFF;
        border-radius: 0px 0px 40px 40px;
        box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    }
}
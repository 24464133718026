/* button open modal */
.openModalPt {
  background-color: #FF1493;
  color: #FFFFFF;
}

/* antd-css overwrite */
.ant-modal {
  padding-bottom: 0 !important;
}
.ant-modal-content {
  padding-top: 8px !important;
  padding-bottom: 40px !important;
}
.ant-modal-body {
  overflow-y: auto !important; 
  overflow-x: hidden;
  max-height: calc(75vh - 10px);
}

/* header-css */
.headerModal {
  display: flex;
  height: 60px;
  border-bottom: 1px solid #D0D2D8;
  align-items: center;
}

.headerModal-title {
  width: 100%;
  color: #6C747B;
  font-size: 18px;
  line-height: 16px;
}

/* body-css */
.bodyModal {
  font-size: 14px;
}

/* footer-css */
.footerModal button span {
  font-size: 16px;
  line-height: 16px;
}
.cardInfoHospital {
  margin-top: 24px;
  box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.2), 0px 2px 2px 0px rgba(46, 49, 56, 0.2);
}
.clinic-content {
  flex-direction: column;
  padding: 20px 36px;
}
.clinic-content .info {
  padding-bottom: 8px;
}
.clinic-content .info img {
  padding-right: 8px;
}
.clinic-content .info span {
  color: #565c67;
}
.reservation-onl {
  background: #ff1493;
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
}
.reservation-onl,
.btnOpenModalQR {
  width: 100%;
  width: 250px;
  height: 48px;
}
.cardInfoHospital-right {
  padding: 0 36px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
}
.cardInfoHospital-right .qrcode {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.btnOpenModalQR {
  background: #8b969e;
}
.btnOpenModalQR img {
  padding-right: 12px;
}
.btnOpenModalQR span {
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
}
.pediatrics {
  color: #6c747b;
  background: #f0f5f8;
  border-radius: 100px;
  padding: 8px 12px 6px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .cardInfoHospital {
    margin-top: 0;
    text-align: left;
    box-shadow: none;
  }
  .clinic-content {
    padding: 0;
  }
  .cardInfoHospital-right {
    padding: 0;
    gap: 16px;
  }
  .btnOpenModalQR,
  .reservation-onl {
    width: 100%;
    height: 60px;
  }
}

/* mobile */
@media (max-width: 768px) {
  .reservation-onl,
  .btnOpenModalQR {
    width: 100%;
  }
}

/* tablet */
@media (min-width: 768px) and (max-width: 1200px)  { 
  .clinic-content {
    padding: 20px;
  }
  .cardInfoHospital-right {
    padding: 0 20px 20px;
  }
  .reservation-onl,
  .btnOpenModalQR {
    width: 100%;
  }
}
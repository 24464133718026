.inputPt01-code {
    height: 60px;
    font-size: 16px;
}

.buttonPt01-qr {
    background-color: #F0F5F8;
    color: #6C747B;
    height: 60px;
    font-size: 16px;
}

.buttonPt01-next {
    width: 100%;
    height: 60px;
    background-color: #FF1493;
    border: none;
    color: #FFFFFF;
}

.buttonPt01-next-disable {
    background-color: #FFC7E3 !important;
    color: #FFFFFF !important;
}

/* overwrite antd-css */
.ant-modal-wrap .modalQRCode {
    width: 800px !important;
}

/* mobile-css */
@media (max-width: 768px) {
    /* overwrite antd-css */
    .ant-modal-wrap .modalQRCode {
        width: 400px !important;
    }
}

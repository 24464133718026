.registerInsurance .btnCf{
    background: #FF1493;
    width: 300px;
    color: #FFFFFF;
    height: 60px;
    font-weight: 700;
    font-size: 16px;
}
.registerInsurance .btnCf:hover {
    background: #FF1493 !important;
    color: #FFFFFF !important;
}
.registerInsurance .ant-modal-title {
    font-weight: 700;
    font-size: 17px;
    color: #565C67;
    border-bottom: solid 1px #D0D2D8;
    padding: 17px 0;
    margin-top: 7px;
}

.registerInsurance .ant-modal-header {
    border-bottom: none !important;
}

.registerInsurance .ant-modal-content{
    width: 1000px;
    left: calc(50% - 400px);
    padding: 24px 40px 48px 40px;
    border-radius: 0 !important;
}

.registerInsurance .ant-card-head {
    background: #F0F5F8 !important;
    padding: 24px 40px 16px 40px !important;
    border-radius: 0 !important;
    border: none;
}

.registerInsurance .ant-card{
    border-radius: 0 !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.registerInsurance .insuranceLimitDateTitle {
    font-size: 15px;
    border-bottom: 1px solid #D0D2D8;
    margin: 0 40px;
    line-height: 48px;
}

.registerInsurance .info {
    font-size: 14px;
    font-weight: 400;
    color: #2E3138;
    white-space: pre-line;
}
.registerInsurance .title {
    font-size: 17px;
    font-weight: 700;
    color: #565C67;
    margin-bottom: 8px;
}

.registerInsurance .help {
    border-radius: 20px;
    background: transparent;
    color: #308AE6 !important;
    font-size: 14px;
    line-height: normal;
    border:  1px solid #308AE6 !important;
    padding: 1px 6px;
    margin-left: 5px;
    font-weight: 400;
    height: 25px;
    width: 54px;
}

.registerInsurance .required {
    border-radius: 20px;
    background: #FF1493;
    color: #FFFFFF !important;
    font-size: 10px;
    line-height: normal;
    border: none !important;
    padding: 1px 8px;
    margin-left: 5px;
}

.registerInsurance .btnAdd {
    display: flex;
    align-items: center;
    border: none;
    padding: 24px;
    background-color: #fff2f9;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700 !important;
    color: #ff1493;
}
.registerInsurance .btnAdd:hover {
    background-color: #fff2f9 !important;
    color: #ff1493 !important;
}

.re-examination-code_print .print-code_button:first-child {
    margin-right: 8px;
}

.registerInsurance .ant-select {
    width: 120px;
    height: 58px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
}

.registerInsurance .ant-select:first-child {
    margin-left: 0 !important;
}

.registerInsurance .approveCopy {
    padding: 24px 40px 48px 40px;
    background: #F9F9F9;
}

.registerInsurance .ant-card-body {
    padding: 0 !important;
}

.registerInsurance .cardContent {
    display: flex;
    justify-content: center;
    padding: 48px;
}


.schedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: #f2f3f5;
}

.schedule .ant-tabs-tab-active {
  background: #ffffff !important;
}

.schedule .ant-tabs {
  padding-top: 30px;
  box-shadow: none;
}

.appointment-count {
  border: solid 1px #308ae6;
  width: 46px;
  color: #308ae6;
  font-size: 10px;
  padding: 2px;
  height: 20px;
  /*padding: 4px 6px 4px 6px;*/
  border-radius: 60px;
  gap: 2px;
}

.appointment-count > img {
  width: 15px;
  /*height: 9.5px;*/
  top: 1px;
  left: 1.5px;
  margin: 2px;
}

.rsvFrameTbl .ant-table-thead > tr > th {
  background: #ffffff !important;
  text-align: center !important;
}

.schedule .ant-tabs-content-holder {
  background: #ffffff;
}

.schedule .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: #f2f3f5;
}

.schedule .ant-tabs-tab-active {
  background: #ffffff !important;
}

.schedule .ant-tabs {
  padding-top: 30px;
}

.appointment-count {
  border: solid 1px #308ae6;
  width: 46px;
  color: #308ae6;
  font-size: 10px;
  padding: 2px;
  height: 20px;
  /*padding: 4px 6px 4px 6px;*/
  border-radius: 60px;
  gap: 2px;
  position: relative;
  display: unset;
  margin: 0 0 0 10px;
  font-weight: 700;
}

.rsvFrameTbl .ant-table-thead > tr > th {
  background: #ffffff !important;
}

.schedule .ant-tabs-content-holder {
  background: #ffffff;
}

.rsvFrameTbl td div .ant-btn {
  border: none;
  float: right;
  color: #acb4bc;
  box-shadow: none;
}

.deActiveFrame {
  background: #f2f3f5;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.rsvFrameTbl td .ant-btn {
  padding: 0 !important;
  height: fit-content;
}

.rsvFrameTbl td .div {
  padding: 0 !important;
}

.rsvFrameTbl td .ant-btn-default {
  background: #f2f3f5;
}

.rsvFrameTbl td button {
  float: right;
}

.rsvFrameTbl table {
  width: 100%;
}

.text-right-align {
  text-align: end;
}

.rsvFrameTbl td {
  border: 1px solid #ecedef;
  font-weight: normal;
  white-space: nowrap;
}


.rsvFrameTbl button:focus {
  outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}

.rsvFrameTbl th {
  border: 1px solid #ecedef;
}

.detailBox {
  width: 250px;
}

.frameList {
  display: flex;
  margin-bottom: -40px;
  min-width: 1366px;
}

.hidden {
  display: none;
}
.rsvFrameTbl {
  min-width: 886px;
  width: 100%;
}

.rsvFrameTbl
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 0 !important;
  background: #ffffff;
}

.rsvFrameTbl
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 0 !important;
}

.rsvFrameTbl .settingBtn {
  white-space: nowrap;
  position: absolute;
  top: calc(50% - 12px);
  right: 0;
  width: fit-content;
  height: fit-content;
  background: #ffffff;
  border: none;
  padding: 0;
}

.rsvFrameTbl thead .ant-table-cell::before{
    display: none !important;
}

.rsvFrameTbl .frameBoxClick {
  width: 100%; 
  height: 100%; 
  padding: 16px 16px;
  border: 2px solid transparent;
}
.rsvFrameTbl .frameBoxClick:focus {
  outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}

.addRsvBtn {
  position: absolute; 
  left: calc(100% - 40px);
  top: calc(100% - 40px);
}

.rsvFrameTbl .ant-table-content {
  overflow: auto;
  height: 63vh;
  border-collapse: collapse;
  width: 100%;
}

.rsvFrameTbl .ant-table-content thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.rsvFrameTbl .ant-table-content::-webkit-scrollbar {
  width: 6px;
}

.rsvFrameTbl .ant-table-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ECEDEF;
  border-radius: 10px;
  width: 8px !important;
}

.rsvFrameTbl .ant-table-content::-webkit-scrollbar-thumb {
  background: #D5D8DC;
  border-radius: 10px;
}

.noScrollBarX .ant-table-content {
 overflow-x: hidden !important;
}

.scheduleTabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab {
  width: unset !important;
}
.dt012-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab {
  width: unset !important;
}
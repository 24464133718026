div.footer-pagination>ul>li>button.ant-pagination-item-link {
    border-color: #D0D2D8 !important;
}
div.footer-pagination>ul>li.ant-pagination-item {
    border-color: #D0D2D8;
}

div.footer-pagination>ul>li.ant-pagination-item.ant-pagination-item-active {
    border-color: #308AE6;
}

.page-icon {
    margin-right: 8px;
}

.page-icon span svg path {
    fill: #ccced4
}

.active span svg path {
    fill: #565c67;
}

.footer-pagination button .ant-btn-icon {
    line-height: 2;
}

.footer-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .footer-pagination {
        width: 100%;
    }
}
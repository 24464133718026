.dt044 {
    width: 440px;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20) !important;
    padding: 20px;
    border: 1px solid #ECEDEF;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dt044 .addBtn {
    width: 100%;
    background: #FF1493;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
    height: 60px;
}

.dt044 .title{
    justify-content: space-between;
    display: flex;
}

.dt044 .selectTime {
    width: 200px;
}

.dt044 .selectTime .ant-select-selection-item {
    background-image: url('src/assets/image/clock.svg');
    background-repeat: no-repeat;
    /*padding-left: 30px !important;*/
    background-position: 85% 6px;
}

.dt044 .treatmentMenu {
    height: 32px;
    margin: 10px 0 10px 0;
    font-size: 17px;
    font-weight: 700;
    color: #565C67;
}

.dt044 *:focus{
  outline: none !important;
  border: 2px solid #308ae6 !important;
  border-radius: 6px;
}

.cardPayment .cardItem {
    border: 1px solid var(--color-alias-border-light, #F2F3F5);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);

    text-align: center;
    height: 100%;
    padding: 24px 24px 40px 24px;
    display: flex;
    flex-direction: column;
}

.cardPayment .cardItem img {
    height: 200px;
    padding: 42px 10px 26.76px 10px;
}

.cardPayment .cardItem span {
    color: #565C67;
    font-size: 16px;
}

.cardPayment .notice {
    display: flex;
    gap: 6px;
}

.cardPayment .notice span {
    align-items: flex-start;
    color: #2E3138;
    line-height: 20px;
}

.cardPayment .errcode {
    padding-left: 10px;
    padding-right: 10px;
}
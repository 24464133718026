.video .videoFrame {
  width: 100%;
  height: 232px;
}
.clinicDetail .detail {
  display: flex;
  align-items: center;
  color: #2e3138;
  padding: 24px;
  background-color: #f8f8f8;
  border-radius: 6px;
}
.image-type1 {
  width: 100%;
}

@media (max-width: 768px) {
  .clinicDetail {
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.2),
      0px 2px 2px 0px rgba(46, 49, 56, 0.2);
    padding: var(--gap-medium, 24px) var(--gap-medium, 24px)
      var(--gap-wide, 36px) var(--gap-medium, 24px);
  }

  .clinicDetail .detail {
    border-radius: 0;
  }
}
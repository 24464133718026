.history-container {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
    width: 75%;
    gap: 24px;
}

.header-wrapper {
    height: 48px;
    color: #6C747B;
    font-size: 20px;
    line-height: normal;
}

.header-h1 {
    display: flex;
    height: 48px;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    color: #565C67;
    font-size: 22px;
    line-height: normal;
}

.title-search {
    height: 48px;
    color: #6C747B;
    font-size: 20px;
    line-height: normal;
}

@media (max-width: 768px) {
    .history-container {
        display: flex;
        padding: 60px 24px 200px 24px;
        flex-direction: column;
        gap: 40px;
        align-self: stretch;
        margin-top: 0;
        width: 100%;
    }
    .header-wrapper {
        display: flex;
        margin: 0;
        font-size: 20px;
        width: auto;
        justify-content: center;
    }
    .title-search {
        display: flex;
        width: auto;
        margin: 0px;
        height: 32px;
        justify-content: center;
        gap: 12px;
        font-size: 20px;
    }
    .header-h1 {
        margin: auto;
    }
}

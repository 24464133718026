.login-form {
  max-width: 300px;
  background-color: white;

}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.container {
  background-color: white;
}
@media (max-width: 768px) {
  .infoHospital {
    text-align: center;
    margin: 36px 24px;
    padding: var(--gap-none, 0px) var(--gap-medium, 24px) var(--gap-wide, 36px) var(--gap-medium, 24px);
    z-index: 1;
    background: rgba(255, 255, 255, .93);
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.2), 0px 2px 2px 0px rgba(46, 49, 56, 0.2);
  }

  .infoHospital-wrap {
    display: flex;
    justify-content: center;
  }

  .booking-avatar-wrap {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
}
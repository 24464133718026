.tabs-setting_container {
  padding-bottom: 20px;
  box-shadow:
    0px 8px 15px 2px rgba(46, 49, 56, 0.1),
    0px 0px 2px 0px rgba(46, 49, 56, 0.2);
}

.tabs-setting_container .ant-tabs-tab-active {
  border: none !important;
}

.tabs-setting-list .ant-tabs-nav-wrap {
  margin: 0 40px;
}
.tabs-setting-list > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  width: 100% !important;
  background: #f2f3f5;
  border-radius: 60px !important;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border: 2px solid #f2f3f5;
}
.tabs-setting-list
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  width: 33.33333% !important;
  display: flex;
}
.tabs-setting-list
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab-active {
  border-radius: 60px !important;
}
:where(
    .css-dev-only-do-not-override-2i2tap
  ).tabs-setting-list.ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab {
  border-bottom-color: #f2f3f5 !important;
}
.tabs-setting-list > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.reservation-management_container {
  width: calc(100vw - 80px);
  margin-left: 40px !important;
  min-width: 1300px;
}

.re-examination-code_conatiner {
  padding-left: 0 !important;
}

.re-examination-code_infor_container {
  padding: 24px 40px;
  border-radius: var(--radius-slightly-rounded, 6px);
  border: 1px solid var(--color-alias-border-light, #f2f3f5);
  box-shadow:
    0px 2px 4px 0px rgba(46, 49, 56, 0.2),
    0px 2px 2px 0px rgba(46, 49, 56, 0.2);
  height: 100%;
}

.re-examination-code_title {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 12px;
  color: #565c67;
}

.re-examination-code {
  display: flex;
  justify-content: space-between;
}

.re-examination-code input {
  height: 58px;
  font-size: 18px;
  font-weight: 400;
  color: #2e3138;
}

.keep-re-exam-code_button {
  border: none;
  border-radius: 6px;
  margin-top: 11px;
  margin-bottom: 24px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 700;
  width: 100px;
  height: 36px;
  background-color: #ff1493;
  color: #ffffff;
  cursor: pointer;
}

.re-examination-code_print {
  display: flex;
  justify-content: space-between;
}

.print-code_button {
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 16px;
  background-color: #fff2f9;
  border-radius: 6px;
  cursor: pointer;
}

.re-examination-code_print .print-code_button:first-child {
  margin-right: 8px;
}

.print-code_text {
  font-size: 14px;
  font-weight: 700;
  margin-left: 12px;
  color: #ff1493;
}

.reservation-url_container {
  margin-top: 24px;
}

.reservation-url_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reservation-url_title_text {
  flex: 1;
  color: #565c67;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 0;
}

.public-status-button {
  height: 30px;
  margin-top: 12px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.public-status-button span {
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 400;
}

.public-status-button .ant-switch-inner-checked {
  line-height: 30px;
  margin-top: 0;
}
.public-status-button .ant-switch-inner-unchecked {
  line-height: 30px;
  margin-top: -30px !important;
}

.public-status-button .ant-switch-inner-checked {
  line-height: 30px;
  margin-top: 0;
}

.public-status-button div {
  top: 6px !important;
}

.public-status-button:hover {
  cursor: pointer;
}

.reservation-url_option_container {
  margin-top: 12px;
}

.reservation-url-item_title {
  display: flex;
  align-items: center;
}

.reservation-url-item_title_text {
  font-size: 15px;
  font-weight: 700;
  color: #2e3138;
  margin-bottom: 0;
  padding-right: 12px;
}

.reservation-url-item_button_contianer {
  display: flex;
  margin-top: 12px;
}

.reservation-url-item_button {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--color-tertiary-200, #b2d6f6);
  background: var(
    --color-button-primary-outline-background-default,
    rgba(255, 255, 255, 0)
  );
}

.reservation-url-item_button:hover {
  cursor: pointer;
}

.reservation-url-item_button span {
  font-size: 14px;
  font-weight: 700;
  color: #308ae6;
}

.mr-20px {
  margin-right: 20px;
}

.ml-12px {
  margin-left: 12px;
}
.mr-12px {
  margin-right: 12px;
}

.mb-24px {
  margin-bottom: 24px;
}

/* re-examination_content */

.re-examination_content_container {
  padding-right: 0 !important;
}

.re-examination_content {
  padding: 24px 40px;
  border-radius: var(--radius-slightly-rounded, 6px);
  border: 1px solid var(--color-alias-border-light, #f2f3f5);
  background: #fff;

  /* box-shadow/strong/low */
  box-shadow:
    0px 2px 4px 0px rgba(46, 49, 56, 0.2),
    0px 2px 2px 0px rgba(46, 49, 56, 0.2);
  height: 100%;
}

.re-examination-home-page_container {
  margin-bottom: 16px;
}

.re-examination-home-page_title {
  font-size: 22px;
  font-weight: 700;
  color: #565c67;
  margin-bottom: 24px;
}

.homepage-url_content {
  display: flex;
}

.homepage-url {
  display: flex;
  align-items: center;
  height: 58px;
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
  width: 100%;
  max-width: 480px;
  margin-right: 40px;
}

.homepage-url span {
  height: 100%;
}

.homepage-url span input {
  height: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #2e3138;
}

.change-homepage_button {
  padding: 8px 24px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  border: none;
  background-color: #a7a7a7;
  border-radius: 6px;
  cursor: not-allowed;
  height: fit-content;
  margin-top: 7px;
  margin-left: 40px;
}

.change-homepage_button.active-button {
  background-color: #ff1493;
  cursor: pointer;
}

.reservation-accept-time_container {
  margin: 24px 0;
}

.reservation-accept-time_title {
  font-size: 22px;
  font-weight: 700;
  color: #565c67;
}

.reservation-accept-time_note {
  display: flex;
  align-items: center;
  padding: 8px 0;
}

.reservation-accept-time_note span {
  font-size: 14px;
  font-weight: 400;
  padding-left: 6px;
}

.reservation-accept-time_select-time {
  display: flex;
  align-items: center;
}

.reservation-accept-time_select-time span {
  font-size: 15px;
  font-weight: 400;
}

.reservation-accept-time_select-time > span {
  white-space: nowrap;
}

.reservation-accept-time_button {
  margin-left: 40px;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  background: #a7a7a7;
  cursor: not-allowed;
}

.reservation-accept-time_button.active-button {
  background-color: #ff1493;
  cursor: pointer;
}

.reservation-accept-time_select-time .ant-select-selector {
  height: 58px !important;
}

.medical-menu_note_container {
  display: flex;
  align-items: center;
}

.medical-menu_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 40px;
}

.medical-menu_note {
  font-size: 14px;
  font-weight: 400;
  margin-left: 6px;
  color: #2e3138;
}

.add-treatment-menu_button,
.add-agreement-form_button {
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  height: 36px;
  background-color: #ff1493;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.agreement-management_container {
  margin-top: 24px;
  min-width: 1300px;
}

.add-agreement-form_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  padding-right: 40px;
}

.add-treatment-menu_button:hover {
  cursor: pointer;
}

.add-treatment-menu_icon {
  width: 16px;
  height: 16px;
  margin-right: 12px;
  font-weight: 700;
}

.add-treatment-menu_icon path {
  fill: #ffffff;
}

.medical-menu_table {
  width: 100%;
  border: 1px solid #ecedef;
  font-size: 14px;
  border-collapse: collapse;
}

.medical-menu_table th {
  border: 1px solid #ecedef;
  font-weight: 700;
  color: #565c67;
  height: 60px;
  padding: 0 12px;
}

.medical-menu_table td {
  border: 1px solid #ecedef;
  font-weight: 400;
  color: #2e3138;
  height: 60px;
  padding: 0 12px;
}

.medical-menu_table_no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.medical-menu_table_no-data span {
  font-size: 14px;
  color: #2e3138;
}

.reservation-frame-public_item {
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.treatment-menu-name_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.treatment-menu-name_container span {
  flex: 1;
  padding-right: 4px;
}

.treatment-menu-name_container div {
  font-size: 14px;
  font-weight: 400;
  padding: 8px 12px;
  color: #ffffff;
  border: none;
  border-radius: 60px;
}

.medical-menu_edit_container {
  display: flex;
  justify-content: center;
}

.medical-menu_edit_button,
.agreement-item_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background-color: #ffffff;
  border: 1px solid var(--color-tertiary-200, #b2d6f6);
  border-radius: 6px;
}

.medical-menu_edit_button:hover,
.agreement-item_button:hover {
  cursor: pointer;
}

.medical-menu_edit_button span,
.agreement-item_button span {
  font-size: 13px;
  font-weight: 700;
  color: #308ae6;
  margin-left: 8px;
}

.medical-menu_consent-form_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.medical-menu_info-icon {
  border-radius: 30px;
}

.add-agreement-form_button svg path {
  fill: #ffffff;
}

.agreement-management_table th,
.agreement-management_table td {
  background-color: #ffffff !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  color: #565c67 !important;
  font-weight: 700;
}

.agreement-management_table td {
  font-weight: 400;
  color: #2e3138;
}

.agreement-item-action_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.agreement-item_button_delete {
  border: none;
  background-color: #f0f5f8;
  border-radius: 6px;
  margin-left: 20px;
}

.agreement-item_button_delete span {
  color: #6c747b;
}

/* Modal CSS */

.agreement-management_modal-header_container {
  height: 60px;
  border-bottom: 1px solid #d0d2d8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.agreement-management_modal-header_title {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #6c747b;
}

.agreement-management_modal-header_close-button {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding: 0;
}

.agreement-management_modal-header_close-button:hover {
  cursor: pointer;
}

.agreement-management_modal-header_close-button svg path {
  fill: #878e9b;
}

.agreement-management_modal-footer_container {
  display: flex;
  justify-content: space-between;
}

.h-60px {
  height: 60px;
}

.agreement-management_modal-footer_container button {
  width: calc(50% - 10px);
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.agreement-management_modal-footer_create-button {
  background-color: #ff1493;
  color: #ffffff;
}

.menu-treatment-single-button {
  background-color: #f0f5f8;
  color: #6c747b;
}

.medical-menu-wrap-input-number {
  width: 120px;
}

.medical-menu-wrap-input-select {
  width: 300px;
}

.agreement-management_modal-footer_create-button:hover {
  cursor: pointer;
}

.agreement-management_modal-footer_cancel-button {
  color: #6c747b;
  background-color: #f0f5f8;
}

.agreement-management_modal-footer_cancel-button:hover {
  cursor: pointer;
}

.agreement-management_modal_note {
  padding: 8px 0;
  display: flex;
  margin-bottom: 32px;
}

.agreement-management_modal_note svg path {
  fill: #2e3138;
}

.agreement-management_modal_note p {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #2e3138;
}

.agreement-management_modal-body .form-item_title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.agreement-management_modal-body span {
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
  line-height: 16px;
}

.agreement-management_modal-body .title_tag {
  display: block;
  height: 16px;
  font-size: 10px !important;
  padding: 0 8px;
  margin: 0 4px;
  border-radius: 20px;
  color: #ffffff !important;
  background-color: #ff1493;
}

.agreement-management_form-input {
  height: 58px;
  font-size: 18px;
  font-weight: 400;
  color: #2e3138;
}

.agreement-management_form-input .ant-input-number-input-wrap {
  height: 100%;
}

.agreement-management_form-input input {
  height: 100%;
}

.agreement-management_modal-body .mr-20px {
  width: calc(100% - 20px);
}

.agreement-form_amount-wrapper {
  display: flex;
}

.agreement-form_amount-col {
  display: flex;
}

.agreement-form_amount-col .ant-form-item {
  flex: 1;
}

.agreement-management_modal-body .mw-120 {
  width: 120px;
}

.agreement-management_modal-body .agreement-form_unit {
  display: flex;
  width: 80px;
  flex-direction: column;
  padding-left: 10px;
  align-items: center;
  margin-bottom: 24px;
}

.agreement-form_unit-delete-btn {
  background-color: transparent;
  border: 1px solid #ecedef;
  border-radius: 6px;
}

.agreement-form_unit-delete-btn:hover {
  cursor: pointer;
}

.add-agreement-form-button_container {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 1px dashed var(--color-alias-border-secondary, #d0d2d8);
}

.add-agreement-form_button .add-agreement-form_button-name {
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 12px;
}

.add-agreement-form-note_container {
  display: flex;
  margin-left: 20px;
  align-items: center;
}

.add-agreement-form-note_container span {
  margin-left: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #2e3138;
}

.agreement-management_modal_title-display-patient {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
}

.agreement-management_modal_title-display-patient h2 {
  font-size: 17px;
  font-weight: 700;
  color: #565c67;
}

.title-display-patient_container {
  padding: 24px;
  background-color: #f8f8f8;
  width: 100%;
}

.title-display-patient_container p {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #2e3138;
}

.delete-agreement-modal_icon {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.delete-agreement-modal_content {
  padding-bottom: 24px;
}

.delete-agreement-modal_content h2 {
  text-align: center;
  font-size: 17px;
  font-weight: 700;
  color: #565c67;
}

.delete-agreement-modal_content p {
  text-align: center;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #2e3138;
}

.consent-form_table-title {
  display: flex;
  justify-content: center;
}

.ant-modal-body::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.ant-modal-body::-webkit-scrollbar-track {
  background-color: #ecedef;
  border-radius: 3px;
}

.ant-modal-body::-webkit-scrollbar-thumb {
  background-color: #d5d8dc;
  border-radius: 3px;
}

.medical-menu_table_container {
  min-width: 1300px;
  height: 50vh;
  min-height: 600px;
  overflow: auto;
}

.medical-menu_table_container thead {
  position: sticky; /* make the table heads sticky */
  top: 0px; /* table head will be placed from the top of the table and sticks to it */
  background-color: #ffffff;
}
.medical-menu_table_container table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow: auto;
}

.tabsetting-scrollbar-style::-webkit-scrollbar {
  display: block;
  width: 6px !important;
}

.tabsetting-scrollbar-style::-webkit-scrollbar-track {
  background-color: #ecedef;
  border-radius: 3px;
}

.tabsetting-scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #d5d8dc;
  border-radius: 3px;
}

.tabsetting-scrollbar-style ::-webkit-scrollbar {
  display: block;
  width: 6px !important;
}

.tabsetting-scrollbar-style ::-webkit-scrollbar-track {
  background-color: #ecedef;
  border-radius: 3px;
}

.tabsetting-scrollbar-style ::-webkit-scrollbar-thumb {
  background-color: #d5d8dc;
  border-radius: 3px;
}

.medical-menu-form_container {
  margin-top: 24px;
}

.medical-menu-form_container .ant-modal-content {
  height: 100%;
}

.medical-menu-form_back_button {
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
}

.medical-menu-form_back_button:hover {
  cursor: pointer;
}

.medical-menu-form_back_button span {
  font-size: 16px;
  font-weight: 400;
  color: #2e3138;
  margin-left: 8px;
}

.medical-menu-form_note_container {
  margin: 24px 0;
  display: flex;
}

.medical-menu-form_note_container span {
  font-size: 14px;
  font-weight: 400;
  color: #2e3138;
  margin-left: 6px;
}

.medical-menu-form_medical-menu-name_title {
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
  margin-bottom: 8px;
}

.medical-menu-form_medical-menu-name input {
  max-width: 600px;
}

.medical-menu-form_input input {
  height: 58px;
  font-size: 18px;
  font-weight: 400;
  color: #2e3138;
}

.medical-menu-form_count-letter {
  font-size: 13px;
  font-weight: 400;
  color: #565c67;
  margin-bottom: 0;
}

.medical-menu-form_common-part-container {
  display: flex;
}

.medical-menu-form_item_container {
  margin-top: 24px;
}

.medical-menu-form_radio-title_wrapper {
  display: flex;
}

.medical-menu-form_radio-note {
  font-weight: 700 !important;
}

.medical-menu-form_item_container p {
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
  margin-right: 4px;
}

.medical-menu-form_radio-button {
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
  padding-right: 24px;
}

.medical-menu-form_common-left-container {
  margin-right: 60px;
}

.not-public-medical-menu_note {
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
}

.medical-menu-form_separate-container {
  margin-top: 48px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow:
    0px 2px 4px 0px rgba(46, 49, 56, 0.2),
    0px 2px 2px 0px rgba(46, 49, 56, 0.2);
}

.medical-menu-form_common-container,
.medical-menu-form_back_container,
.medical-menu-form_note_container,
.medical-menu-form_separate-container {
  margin-left: 40px;
  margin-right: 40px;
}
.divider {
  height: 24px;
}

.medical-menu-form_separate-header {
  padding: 12px 40px;
  background: #f8f8f8;
  display: flex;
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.medical-menu-form_separate-header p {
  margin-bottom: 0;
  color: #565c67;
  font-size: 17px;
  font-weight: 700;
}

.mr-32 {
  margin-right: 32px;
}

.medical-menu-form_separate_public-status_wrapper {
  display: flex;
  margin-left: 24px;
}

.medical-menu-form_separate_public-status-item {
  display: flex;
  align-items: center;
}

.medical-menu-form_separate_public-status-item span {
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
  margin-left: 4px;
}

.medical-menu-form_separate-content_wrapper {
  padding: 24px 40px 48px;
}

.medical-menu-form_separate-content_wrapper p {
  font-weight: 400;
}

.medical-menu-form_separate_title-form-item {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}

.medical-menu-form_separate_title-form-item p {
  margin-bottom: 0;
  padding-right: 4px;
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
}

.medical-menu-form_separate_title-note {
  color: #878e9b !important;
}

.medical-menu-form_wrapper {
  display: flex;
}

.medical-menu-form_input_wrapper {
  display: flex;
  align-items: center;
}

.medical-menu-form_input-number {
  width: 120px !important;
}

.medical-menu-form_title-before {
  padding: 6px 12px;
  background-color: #f0f5f8;
  border-radius: 60px;
  display: flex;
  margin-bottom: 24px;
  line-height: 20px;
  text-align: center;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #6c747b;
}

.medical-menu-form_unit-after {
  margin-bottom: 24px;
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
  margin-left: 20px;
}

.ml-100 {
  margin-left: 100px;
}

.w-full {
  width: 100%;
}

.w-full .ant-form-item {
  width: 100%;
}

.mr-4 {
  margin-right: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mx-4 {
  margin-right: 4px;
  margin-left: 4px;
}

.mb-0 {
  margin-bottom: 0;
}

.normal-text {
  font-size: 15px;
  font-weight: 400;
  color: #2e3138;
}

.medical-menu-form_submit-button {
  margin-top: 48px;
  width: 480px;
  height: 60px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  border-radius: 6px;
  background-color: #ff1493;
  color: #ffffff;
}

.medical-menu-form_submit-button:hover {
  cursor: pointer;
}

.medical-menu-form_submit-button_wrapper {
  display: flex;
  justify-content: center;
}

.medical-menu-form_input .ant-select-selector {
  height: 58px !important;
}

.medical-menu-form_input-select {
  width: 300px !important;
  height: 58px;
}

/* Print PDF style */
.print-re-exam-code-container {
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 80px;
  height: 100%;
}

.print-re-exam-code-container .print-clinic-title {
  font-weight: 700;
  font-size: 24px;
}

.print-re-exam-code-container .print-clinic-address {
  font-size: 18px;
  margin-bottom: 58px;
  margin-top: 18px;
}

.print-re-exam-code-container .print-clinic-guild {
  font-size: 18px;
  margin-top: 18px;
}

.print-re-exam-table {
  width: 100%;
  border: 1px solid;
  margin-top: 52px;
}

.print-re-exam-table tr {
  border: 1px solid;
}

.print-re-exam-table th {
  border: 1px solid;
  width: 50%;
  height: 52px;
  font-weight: 600;
}

.print-re-exam-table td {
  border: 1px solid;
  height: 240px;
}

.print-re-exam-table td div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.print-re-exam-code-container .print-note-text {
  margin-top: 18px;
  width: 100%;
}

.print-re-exam-code-container .print-link-ishachoku {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 52px;
}

.display-none {
  display: none;
}

.div-in-able-touse-note {
  display: flex;
  align-items: center;
}

.div-in-able-touse-note span {
  margin-left: 2px;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* mobile */
@media (max-width: 768px) {
  .ml-100 {
    margin-left: 50px;
  }
}

.tabs-setting {
  min-width: 1366px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .medical-menu_table_container {
    padding-left: 20px;
  }

  .tabsetting-scrollbar-style {
    padding-left: 20px;
  }

  .schedule > .ant-row > .ant-col > .ant-tabs > .ant-tabs-nav {
    min-width: 1300px;
  }
}

@media (max-width: 768px) {
  .tabsetting-scrollbar-style {
    padding-left: 40px;
  }
}

@media (max-width: 1366px) {

  .consent-form_modal-wrapper::-webkit-scrollbar {
    display: none;
  }

  .hideScrollbar {
    overflow-y: auto !important;
  }

  .hideScrollbar::-webkit-scrollbar {
    display: block;
    height: 6px !important;
  }
  .hideScrollbar::-webkit-scrollbar:vertical {
    display: none;
  }
  .hideScrollbar::-webkit-scrollbar-track {
    background-color: #ecedef;
    border-radius: 3px;
  }

  .hideScrollbar::-webkit-scrollbar-thumb {
    background-color: #d5d8dc;
    border-radius: 3px;
  }

  .openModal::-webkit-scrollbar {
    display: none;
  }

  .openModal .consent-form_modal-wrapper::-webkit-scrollbar {
    display: block;
  }

  .openModal .consent-form_modal-wrapper::-webkit-scrollbar:vertical {
    display: none;
  }
  .openModal .consent-form_modal-wrapper::-webkit-scrollbar-track {
    background-color: #ecedef;
    border-radius: 3px;
  }

  .openModal .consent-form_modal-wrapper::-webkit-scrollbar-thumb {
    background-color: #d5d8dc;
    border-radius: 3px;
  }
}

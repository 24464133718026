.payment-info-head {
    display: flex;
    padding: 16px 24px;

    flex-direction: column;
    align-items: flex-start;
    gap: 0px;

    align-self: stretch;

    border-radius: 0px;
    border: 1px solid #F2F3F5;
    background: #FFF;

    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
}
.payment-info-head>div {
    display: flex;
    height: 56px;
    padding: 0px 8px 0px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.payment-info-head>div>div:nth-child(1) {
    display: flex;
    height: 17px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;

    color: #2E3138;
    font-family: 'Meiryo';
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
}

.payment-info-head>div>div:nth-child(1)>span {
    color: #878E9B;
    font-family: 'Meiryo';
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
}
.payment-info-head>div>div:nth-child(2) {
    color: #565C67;
    font-family: 'Meiryo';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}
.payment-info-note {
    display: flex;
    padding: 8px 0px;
    align-items: flex-start;
    gap: 6px;
    margin-top: 20px;
    background: #FFF;
}
.table-time {
  padding: 16px 24px;
  border-radius: 6px;
  border: 1px solid #f2f3f5;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.2),
    0px 2px 2px 0px rgba(46, 49, 56, 0.2);
}
.table-time tbody tr td:nth-child(1) span {
  color: #565c67;
  line-height: 16px;
}
.table-time tbody tr:last-child td {
  border-bottom: none;
}

/* overwrite antd-css */
.table-time .ant-table-thead > tr > th:nth-child(1) {
  width: 140px !important;
}
.table-time .ant-table-thead > tr > th {
  background: none !important;
}
.table-time .ant-table-thead > tr > th > span {
  color: #565c67;
  line-height: 16px;
  display: block;
}
.table-time .ant-table-cell:not(:nth-child(1)) {
  text-align: center !important;
}
.table-time .ant-table-thead > tr > th .sunday {
  color: #e42993;
}
.table-time .ant-table-thead > tr > th .saturday {
  color: #1e6097;
}
.table-time .ant-table-thead > tr > th:last-child > span {
  color: #e42993;
}
.table-time .ant-table-wrapper .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}
.table-time .ant-table-content > table {
  table-layout: fixed !important;
}

.table-time .ant-table-thead > tr > th,
.table-time .ant-table-thead > tr > td,
.table-time .ant-table-tbody > tr > td,
.table-time .ant-table-tbody > tr > td {
  padding: 0 !important;
  height: 48px !important;
}

@media (max-width: 768px) {
  .table-time {
    padding: 16px;
  }
  /* overwrite antd-css */
  .table-time .ant-table-thead > tr > th:nth-child(1) {
    width: 120px !important;
  }
}

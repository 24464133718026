.buttonPt01-code {
    height: 60px;
    font-size: 16px;
    background-color: #F0F5F8;
    color: #6C747B;
}

.scannerPt01-camera {
    display: flex;
    justify-content: center
}
.scannerPt01-message {
    position: absolute;
    top: 50%;
    width: 230px;
}

/* mobile */
@media (max-width: 768px) {
    .scannerPt01-message {
        top: 40%;
    }
}
/* button-css */
.buttonPt10-left {
  background-color: #FF1493; 
  color: #FFFFFF;
  width: 100%;
  height: 60px;
  cursor: pointer;
}

.buttonPt10-right {
  background-color: #f0f5f8;
  color: #6C747B;
  width: 100%;
  height: 60px;
  cursor: pointer;
}
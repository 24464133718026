.box-result {
    width: auto;
}

.box-result-header {
    display: flex;
    height: 32px;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    color: #565C67;
    font-size: 16px;
    line-height: normal;
}

.box-result-body > div .ant-table-footer {
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 0px !important;
}

.box-result-body .btn-register {
    background-color: #FFF2F9;
    color: #FF1493;
    border: #FFF2F9;
    font-weight: 700;
}
.box-result-body .btn-register:hover {
    background-color: #FFF2F9 !important;
    color: #FF1493 !important;
    border: #FFF2F9 !important;
    font-weight: 700 !important;
}

.box-result-body .ant-table-content > table {
    border-collapse: collapse;
}

.emptyText {
    color: #000000;
}

.name-clinic, .name-clinic:visited, .name-clinic:hover {
    color: #1677ff;
}

.box-result-body table {
    min-width: 1000px;
}

.box-result-body table th.ant-table-cell {
    text-align: center;
}

.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon,
.ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon {
    display: none;
}

.item-first, .item-last {
    display: none !important;
}

@media (max-width: 768px) {
    .box-result {
        margin: 0;
        width: auto;
    }
    .box-result-body {
        display: flex;
        flex-direction: column;
        width: auto;
    }
    .box-result-body div.ant-table-content {
        overflow-x: scroll;
    }
}

@media (max-width: 1400px) {
    .box-result-body div.ant-table-content {
        overflow-x: scroll;
    }
}
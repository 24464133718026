.booking {
  border-radius: 6px;
  border: 1px solid #f2f3f5;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.2), 0px 2px 2px 0px rgba(46, 49, 56, 0.2);
  padding: 24px 36px 36px 36px;
  margin-bottom: 24px;
}
.booking .headingRow {
  align-items: center;
}
.booking .headingRow,
.booking .pediatricsRow {
  margin-bottom: 20px;
}
.booking-introduce span {
  color: #2e3138;
  font-size: 15px;
  line-height: normal;
}
.headingColReservation {
  text-align: right;
}
.headingColReservation .reservation-onl{
  min-width: unset;
  max-width: 200px;
}
.introduction span {
  color: #2e3138;
  font-size: 14px;
  line-height: 24px;
}
.bookingcalender {
  border: 1px solid #f2f3f5;
}
.chargeAndSchedule {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.table-weekday {
  display: flex;
  flex-direction: row;
}
.table-weekday .table-weekday-left {
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  padding: 0 12px;
  color: #565C67;
}
.table-weekday .daysNumber {
  font-size: 10px;
}

.table-weekday .ant-table-cell>div>p:nth-child(1) {
  margin-bottom: 0 !important;
  color: #565C67;
  font-size: 14px;
  line-height: 16px;
}
.table-weekday .ant-table-cell>div>p:nth-child(2) {
  margin-bottom: 0 !important;
  color: #565C67;
  font-size: 10px;
  line-height: 16px;
}

.table-weekday .ant-table-cell:nth-child(1) span {
  color: #565C67;
  font-size: 14px;
  line-height: 16px;
}
.table-weekday .ant-table-thead>tr>th .sunday {
  color: #E42993;
}
.table-weekday .ant-table-thead>tr>th .saturday {
  color: #308AE6;
}

/* overwrite antd-css */
.table-weekday .ant-table-thead > tr > th,
.table-weekday .ant-table-thead > tr > td,
.table-weekday .ant-table-tbody > tr > td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.table-weekday .ant-table-content > table {
  table-layout: fixed !important;
}

.table-weekday .ant-table-thead > tr > th,
.table-weekday .ant-table-tbody > tr > td {
  border-inline-end: unset !important;
}
.table-weekday .ant-table-thead > tr > th:last-child,
.table-weekday .ant-table-tbody > tr > td:last-child {
  border-inline-end: 1px solid #f0f0f0 !important;
}

.treatment-menus-blank {
  color: #565c67;
  font-size: 15px;
  line-height: normal;
}

@media (max-width: 768px) {
  .booking {
    border: 0;
    box-shadow: unset;
    padding: 0;
  }
  .booking .headingRow {
    margin-bottom: 0px;
  }
  .booking .pediatricsRow,
  .booking .introduceRow {
    margin-bottom: 24px;
  }
  .reservation {
    order: 2;
  }
}

/* < PC */
@media (max-width: 1200px) {
  .table-weekday .table-weekday-left { 
    padding: 0 5px;
    min-width: 60px;
    white-space: normal;
  }
}
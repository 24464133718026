.confirmBeforeEditCard .confirmBtn {
    background: #FF1493;
    width: 300px;
    color: #FFFFFF;
    height: 60px;
    font-weight: 700;
    font-size: 16px;
}
.confirmBeforeEditCard .confirmBtn:hover {
    background: #FF1493 !important;
    color: #FFFFFF !important;
}

.confirmBeforeEditCard .ant-modal-title {
    font-weight: 700;
    font-size: 17px;
    color: #565C67;
    border-bottom: solid 1px #D0D2D8;
    padding: 17px 0;
    margin-top: 7px;
}

.confirmBeforeEditCard .ant-modal-header {
    border-bottom: none !important;
}

.confirmBeforeEditCard .msg{
    color: #2E3138;
    background: #F8F8F8;
    padding: 24px;
    margin-bottom: 40px;
    margin-top: 40px;
}

.confirmBeforeEditCard .ant-modal-content{
    width: 800px;
    left: calc(50% - 400px);
    padding: 24px 40px 48px 40px;
    border-radius: 0 !important;
}

.confirmBeforeEditCard .errMsg {
    color: #ff4d4f;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
}
.biological-data-container {
    display: flex;
    padding: 24px 40px 48px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    border: 1px solid #F2F3F5;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    margin-bottom: 20px;
}

.biological-data-title {
    display: flex;
    padding: 0px;
    align-items: center;
    gap: 24px;
    align-self: stretch;

    border-radius: 0px;
}

.biological-data-title>div.title {
    display: flex;
    height: 32px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;

    color: #565C67;
    font-family: 'Meiryo';
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.biological-data-title>button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    height: 44px;
    padding: 8px 50px;
    gap: 12px;

    border-radius: 6px;
    background: #FF1493;

    color: #FFF;
    font-family: 'Meiryo';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}

.biological-data-title > button:hover {
    color: #FFFFFF !important;
    border-color: #FF1493 !important;
}

.biological-data-note {
    align-self: stretch;

    color: #2E3138;
    font-family: 'Meiryo';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.biological-data-note>p {
    margin: 0;
}

.biological-data-box {
    display: flex;
    padding: 0px;
    align-items: flex-start;
    gap: 60px;
    align-self: stretch;

    border-radius: 0px;
}

.biological-data-box-result {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.result-header {
    display: flex;
    height: 32px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
}

.result-header>span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;

    color: #2E3138;
    font-family: 'Meiryo';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.result-content .result {
    display: flex;
    height: 56px;
    padding: 0px 8px 0px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-bottom: 1px solid #E3E5E8;
}

.result-content .result>span:first-child {
    display: flex;
    height: 17px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;

    color: #2E3138;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.biological-data-box-camera {
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    border-radius: 0px;
}

.biological-data-box-camera>div:first-child {
    position: relative;
}

.biological-data-box-camera>div:first-child>div.loading {
    position: absolute;
    top: 46%;
    left: 46%;
    z-index: 1000;
}

.biological-data-box-camera>div:last-child {
    display: flex;
    width: 100%;
}

.biological-data-box-camera>div>button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    padding: 25px 60px;
    gap: 10px;

    border-radius: 60px;
    background: #FF1493;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}

.camera {
    display: flex;
    width: 100%;
    justify-content: center;
}

.camera .video-js {
    border-radius: 36px;
}

.camera .video-js .vjs-control-bar {
    background-color: rgb(255 255 255 / 0%);
    border-radius: 0 0 36px 36px;
}

.camera .video-js .vjs-my-fancy-modal {
    border-radius: 36px;
}

.camera .video-js .vjs-close-button {
    display: none;
}

.vjs-modal-dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px !important;
}

.camera-none {
    display: none;
}

.loading-icon {
    display: flex;
    width: 100%;
    justify-content: center;
}

.explain {
    color: #878E9B;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

/* mobile */
@media (max-width: 768px) {
    .biological-data-box {
        flex-direction: column-reverse;
        align-items: center;
        gap: 16px;
    }

    .biological-data-container {
        padding: 16px 16px 48px 16px;
    }

    .biological-data-box-camera>div .image {
        max-width: 320px;
        max-height: 320px;
    }

    .camera .video-js {
        max-width: 320px;
        max-height: 320px;
    }

    .biological-data-title>div.title {
        justify-content: center;
    }
}
  
/* tablet */
@media (min-width: 768px) and (max-width: 1200px)  { 
    .biological-data-box-camera>div>button {
        flex: 1 !important;
    }
}

@media (max-width: 1366px) and (max-height: 768px) {
    
    .biological-data-box {
        flex-direction: column-reverse;
        align-items: center;
        gap: 16px;
    }
}
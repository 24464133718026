.text-bold-styles {
    font-weight: 700;
}
.text-normal-styles {
    font-weight: 400;
}
.need {
    border-radius: 20px;
    background: #FF1493;
    color: #FFFFFF !important;
    font-size: 10px;
    line-height: normal;
    border: none !important;
    padding: 1px 8px;
}
.messErr {
    color: #ff4d4f;
    font-size: 14px;
    line-height: 16px;
}
.confirm .ant-typography {
    color: #308AE6;
}
.ant-modal-content {
    padding: 8px 32px 40px 32px !important;
}
.errcode {
    color: #ff4d4f;
    padding-top: 5px;
}
.cardHeaderMenu {
 text-align: center;   
 background-color: #fff;
}

.cardHeaderMenu button {
    min-width: 100px;
}

.cardHeaderMenu .login {
    color: #FF1493;
    border-color: #FFC7E3;
}

.cardHeaderMenu .register,
.cardHeaderMenu .login-medical {
    color: #308AE6;
    border-color: #B2D6F6;
}

.cardHeaderMenu .login,
.cardHeaderMenu .register,
.cardHeaderMenu .login-medical {
    height: auto;
}

/* mobile & tablet */
@media (max-width: 992px) {
    .cardHeaderMenu {
        padding: 36px;
        box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    }
    .cardHeaderMenu button {
        width: 100%;
        padding: 10px;
    }
}
.cards{
    padding: 80px 70px;
    display: flex;
    flex-wrap: wrap;
    width: 794px;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
}
.card {
    width: 326px;
    height: 160px;
    box-sizing: border-box;
    padding: 20px 30px;
}
.card>div {
    padding-bottom: 4px;
}
.card>div:last-child>span {
    font-weight: bold;
}
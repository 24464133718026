.modal-pharmacy-guide .colCardItem {
    flex: 1;
}

.modal-pharmacy-guide .cardItem {
    border: 1px solid var(--color-alias-border-light, #F2F3F5);
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);

    text-align: center;
    height: 100%;
    padding: 24px 24px 40px 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.modal-pharmacy-guide .cardItem img {
    height: 200px;
    padding: 42px 10px 26.76px 10px;
}

.modal-pharmacy-guide .cardItem span {
    color: #565C67;
    font-size: 16px;
}

.modal-pharmacy-guide .splitCardItem {
    display: flex;
    align-items: center;
    justify-content: center;
}


div.step-by-step div.image-box-mb {
    display: flex;
    padding: 0px 6px 40px 3px;
    flex-direction: column;
    align-items: center;
}

div.step-by-step div.image-box-mb div {
    display: flex;
    padding: 0px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    height: 350px;

    border-radius: 0px;
}

div.step-by-step div.image-box-mb div {
    border: 1px solid #F2F3F5;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(46, 49, 56, 0.20), 0px 2px 2px 0px rgba(46, 49, 56, 0.20);
    padding: 30px;
}

div.step-by-step div.image-box-mb div img {
    width: 220px;
    height: 220px;
}

div.step-by-step div.image-box div span {
    font-size: 16px;
    font-weight: 700;
}

div.step-by-step div.image-box-mb div span {
    font-size: 15px;
    font-weight: 700;
}

div.step-by-step ul.slick-dots li button {
    border-radius: 3px 3px 3px 3px;
}

div.step-by-step ul.slick-dots li.slick-active button {
    width: 14px;
    height: 14px;
    border-radius: 7px 7px 7px 7px;
    background-color: #FF1493;
}

div.step-by-step ul.slick-dots {
    align-items: baseline;
    gap: 5px;
}

.modal-pharmacy-guide .pharmacy_guide_container .slick-slider {
    width: 100%;
}

.pharmacy_guide_container>.ant-row>.ant-col>.topic {
    font-size: 15px;
    font-weight: 700;
    color: #2E3138;
}

.pharmacy_guide_container .splitCardItem img.arrow-right {
    width: 40px;
    height: 40px;
}

.pharmacy_guide_container>.ant-modal-content>.ant-modal-header {
    border-bottom: 1px solid #D0D2D8;
    height: 60px;
    margin-top: 8px;
    display: flex;
    align-items: center;
}

.pharmacy_guide_container>.ant-modal-content>.ant-modal-header>.ant-modal-title {
    font-size: 18px;
    color: #6C747B;
    font-weight: 700;
}

.pharmacy_guide_container>.ant-row>.ant-col>.message-guide {
    display: flex;
    align-items: flex-start;
    gap: 6px;
}

/* overwrite antd-css */
.modal-pharmacy-guide .ant-modal-header {
    border-bottom: 1px solid #D0D2D8;
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.modal-pharmacy-guide .ant-modal-content .ant-modal-close {
    height: 40px;
    display: flex;
    align-items: center;
}
/* < tablet */
@media (max-width: 1200px) { 
    .modal-pharmacy-guide .ant-modal-body {
        padding-right: 10px;
    }
}

@media (max-width: 768px) {
    .modal-pharmacy-guide .pharmacy_guide_container {
        margin-bottom: 24px;
    }
}
.scheduleTabs .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  background: #f2f3f5;
}

.scheduleTabs .ant-tabs-tab-active {
  background: #ffffff !important;
}

.scheduleTabs .ant-tabs {
  padding-top: 24px;
}

.scheduleTabs .ant-tabs-extra-content {
  flex: auto !important;
}

.scheduleTabs .ant-tabs-tab {
  display: flex;
  justify-content: right;
  min-width: unset !important;
}

.scheduleTabs .tabs-extra-schedule-button {
  padding-left: 40px;
  align-items: center;
}

.tabs-extra-schedule-button .fieldSearchDate { 
  padding-right: 20px;
}

.scheduleTabs .ant-tabs-tab .ant-tabs-tab-active {
  border: 1px solid;
  border-radius: 10px;
}
.scheduleTabs .ant-tabs-nav {
  gap: 20px;
  align-items: center;
}
.scheduleTabs .ant-tabs-nav-wrap {
  justify-content: end;
  flex: unset !important;
  min-width: unset !important;
  order: 3;
  overflow: visible !important;
  align-items: center;
}
.scheduleTabs .ant-tabs-extra-content:last-child {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.scheduleTabs .ant-tabs-nav-wrap .ant-tabs-nav-list {
  margin-right: 40px;
  margin-bottom: 10px;
  box-shadow: none;
  max-height: 36px;
}
.scheduleTabs .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #e3e5e8 !important;
  width: unset !important;
}
.scheduleTabs .ant-tabs-tab:nth-child(1) {
  border-radius: 10px 0 0 10px !important;
  margin-bottom: 10px;
}
.scheduleTabs .ant-tabs-tab:nth-child(2) {
  border-radius: 0 10px 10px 0 !important;
  margin-bottom: 10px;
  width: unset !important;
}

.scheduleTabs .buttonShowModal {
  background-color: #ff1493;
  color: #ffffff;
  width: 100%;
  height: 36px;
  cursor: pointer;
}

.scheduleTabs-container {
  flex: 1;
}

@media (max-width: 1600px)  { 
  .tabs-extra-schedule-button .fieldSearchDate { 
      padding-bottom: 20px;
  }
}

@media (max-width: 1200px) {
  .scheduleTabs-container .ant-tabs-content-holder {
    margin-left: 20px;
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .scheduleTabs .ant-tabs-nav {
    align-items: baseline;
  }
  .scheduleTabs-container .ant-tabs-content-holder {
    margin-left: 40px;
  }
}

.schedule .ant-tabs-nav::before{
  display: none;
}
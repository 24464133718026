/* button-css */
.wrapper-login .ant-modal-close {
  top: 30px;
}
.buttonPt04-login {
  background-color: #FF1493;
  color: #FFFFFF;
  width: 100%;
  height: 60px;
  cursor: pointer;
}

.buttonPt04 {
  color: #6C747B;
  width: 100%;
  height: 60px;
}
.wrapper-login :where(.css-dev-only-do-not-override-1vr7spz).ant-form-item .ant-form-item-explain-error {
  margin-bottom: 20px;
}
.wrapper-login .login-container>.form-login {
  width: 100%;
}
.wrapper-login .forget-password {
  color: #308AE6;
  font-size: 16px;
  line-height: normal;
  cursor: pointer;
}
.wrapper-login>.ant-modal-content>.ant-modal-header {
  border-bottom: 1px solid #D0D2D8;
  height: 60px;
  /* margin: 8px 12px 0 0; */
  display: flex;
  align-items: center;
}
.wrapper-login>.ant-modal-content>.ant-modal-header>.ant-modal-title {
  color: #6C747B;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}
.wrapper-login .ant-modal-close {
  margin: 6px 12px 0 0;
}
.login-container {
  padding: 8px;
  margin-top: 32px;
}
.wrapper-login>.ant-modal-content>.ant-modal-body>.ant-row {
  display: block;
}